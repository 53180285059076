/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
    CustomerServiceOutlined,
    WhatsAppOutlined,
    TwitterOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { database } from "../../config/firebase";

import {
    Layout,
    Form,
    Input,
    Card,
    Button,
    Tooltip,
    Avatar,
    Tag,
    Image,
    Space,
    Divider,
    Row,
    Col,
    Typography,
    BackTop,
} from "antd";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { SayButton } from "react-say";

const { Content } = Layout;
const { Meta } = Card;
const { TextArea } = Input;
const { Paragraph } = Typography;

function Publicacao() {
    const { state } = useLocation();
    console.log("estadooo", state);

    const [user, setUser] = useState({} as any);
    const [post] = useState([""]);

    useEffect(() => {
        const currentUser = JSON.parse(
            localStorage.getItem("@janela:user") as string
        );

        setUser(currentUser);
    }, []);

    console.log(state.authorImage);

    function OpenModal() {
        Swal.fire({
            icon: "warning",
            title: "Você precisa realizar login para continuar",
            showConfirmButton: false,
            timer: 1500,
        });
    }

    console.info(state.materia);

    const history = useHistory();

    interface DataTypePost {
        uid: string;
        autor: string;
        titulo: string;
        subtitulo: string;
        cartolas: string;
        materia: {};
        bio: string;
        imagem: string;
        categoria: string;
        date: string;
        photoCredit: string;
        tempoLeitura: string;
        autorImagem: string;
        favcolor: string;
    }

    let data2;
    const [data, setData] = useState<DataTypePost[]>([]);

    async function getPost() {
        Swal.fire({
            title: " Matéria...",
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();
                await database
                    .ref()
                    .child("posts")
                    .get()
                    .then((snapshot) => {
                        if (snapshot.exists()) {
                            data2 = snapshot.val();
                            const data3 = Object.entries(data2).map(
                                (d) => d[1]
                            ) as DataTypePost[];
                            setData(data3);
                        } else {
                            console.log("No data available");
                        }
                    })
                    .then(() => {
                        Swal.close();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
        });
    }
    useEffect(() => {
        getPost();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    console.log(data);

    function redirect(d: DataTypePost) {
    
        history.push(`./perfil`, {
            id: d.uid,
            autor: d.autor,
            title: d.titulo,
            subtitulo: d.subtitulo,
            cartola: d.cartolas,
            bio: d.bio,
            categoria: d.categoria,
            materia:d.materia,
            imagem: d.imagem,
            date: d.date,
            photoCredit: d.photoCredit,
            tempoLeitura: d.tempoLeitura,
            authorImage: d.autorImagem,
            tagColor: d.favcolor,
        });
    }

    return (
        <>
            <Layout>
                <Content
                    className="layout"
                    style={{
                        padding: 24,
                        margin: 0,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Card style={{ maxWidth: "50%", padding: "15px" }}>
                        <div
                            style={{
                                paddingBottom: "10px",
                                paddingLeft: "15px",
                            }}
                        >
                            <Tag
                                style={{ textTransform: "uppercase" }}
                                color={state.tagColor}
                            >
                                {state.cartola}
                            </Tag>

                            <Tag style={{ textTransform: "uppercase" }}>
                                {state.categoria}
                            </Tag>
                        </div>

                        <div
                            style={{
                                height: 30,
                            }}
                        >
                            <br />
                            <div
                                style={{
                                    borderLeft: `14px solid  ${state.tagColor}`,
                                }}
                            >
                                <h1
                                    style={{
                                        fontWeight: "bolder",
                                        paddingLeft: "10px",
                                        fontSize: "30px",
                                    }}
                                >
                                    {state.title}
                                </h1>
                                <p
                                    style={{
                                        paddingLeft: "15px",
                                        fontSize: "20px",
                                    }}
                                >
                                    {state.subtitulo}
                                </p>
                            </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <Divider />
                        <br />
                        <br />
                        <br />
                        <br />

                        <Row style={{ marginTop: "5rem" }}>
                            <Col
                                style={{ cursor: "pointer" }}
                                span={12}
                                onClick={() => redirect(state)}
                            >
                                <Meta
                                    avatar={<Avatar src={state.authorImage} />}
                                    title={state.autor}
                                />
                            </Col>
                            <Col span={12}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <strong>
                                            Tempo de Leitura:
                                            <Space direction="vertical">
                                                <Tag>
                                                    {state.tempoLeitura} min
                                                </Tag>
                                            </Space>
                                        </strong>
                                        <strong>
                                            Publicado em:
                                            <Space direction="vertical">
                                                <p style={{ color: "#989898" }}>
                                                    {state.date}
                                                </p>
                                            </Space>
                                        </strong>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <div
                            style={{
                                flexDirection: "column",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Image
                                style={{ maxHeight: "400px" }}
                                src={state.imagem}
                            >
                                <Tag color="#f50">{state.cartola}</Tag>{" "}
                            </Image>
                            <Divider> Foto: {state.photoCredit}</Divider>
                        </div>

                        <br />
                        <br />
                        <div style={{ textAlign: "left" }}>
                            <Tooltip title="Ouvir Matéria">
                                <Button>
                                    <SayButton
                                        style={{ backgroundColor: "none" }}
                                        onClick={(event: any) =>
                                            console.log(event)
                                        }
                                        speak={state.materia}
                                    >
                                        <CustomerServiceOutlined size={150} />
                                    </SayButton>
                                    <span style={{ paddingLeft: "10px" }}>
                                        Clique para ouvir o texto.
                                    </span>
                                </Button>
                            </Tooltip>
                            <br />
                            <br />
                            <strong>
                                Atenção: após clicar uma vez, o texto presente
                                nesta página será lido sem interrupção.
                            </strong>
                        </div>

                        <br />
                        <br />
                        <Paragraph
                            style={{ textIndent: "1em", textAlign: "justify" }}
                        >
                            <p>{state.materia.split(/\s*;\s*/)}</p>
                        </Paragraph>

                        <br />
                        <p>{post}</p>
                        <div
                            style={{
                                paddingBottom: "30px",
                                paddingLeft: "30px",
                            }}
                        >
                            <Button
                                size="large"
                                icon={<TwitterOutlined />}
                                style={{
                                    marginRight: "10px",
                                    backgroundColor: "#399BF0",
                                    color: "#fff",
                                }}
                            >
                                <a
                                    style={{
                                        paddingLeft: "10px",
                                        color: "white",
                                        fontWeight: "bold",
                                    }}
                                    className="twitter-share-button"
                                    href={`https://twitter.com/intent/tweet?text=${state.title}+${state.subtitulo}%20%0A%0A%20https://www.janela.jor.br/`}
                                    data-size="large"
                                >
                                    Compartilhar no Twitter
                                </a>
                            </Button>

                            <Button
                                size="large"
                                icon={<WhatsAppOutlined />}
                                style={{
                                    marginRight: "10px",
                                    backgroundColor: "#4FEB61",
                                    color: "#fff",
                                }}
                            >
                                <a
                                    style={{
                                        paddingLeft: "10px",
                                        color: "white",
                                        fontWeight: "bold",
                                    }}
                                    href={`https://api.whatsapp.com/send?text=${state.title}+${state.subtitulo}%20%0A%0A%20https://www.janela.jor.br/`}
                                >
                                    Compartilhar no WhatsApp
                                </a>
                            </Button>

                            <iframe
                                src={`https://www.facebook.com/plugins/share_button.php?href=${state.title}+${state.subtitulo}`}
                                width="205"
                                height="20"
                                data-size="large"
                                style={{ border: "none", overflow: "hidden" }}
                                scrolling="no"
                                frameBorder="0"
                                allowFullScreen={true}
                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                            ></iframe>
                        </div>

                        <Divider />
                        {!user && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Button type="primary" onClick={OpenModal}>
                                    Adicionar um Comentário
                                </Button>
                            </div>
                        )}

                        {user && (
                            <Card>
                                <div
                                    style={{
                                        borderLeft: `14px solid  ${state.tagColor}`,
                                        height: 30,
                                    }}
                                >
                                    <h1
                                        style={{
                                            fontWeight: "bolder",
                                            paddingLeft: "10px",
                                        }}
                                    >
                                        Deixe seu comentário
                                    </h1>
                                    <Form>
                                        <Form.Item>
                                            <span>
                                                <strong>Nome</strong>
                                            </span>
                                            <Input
                                                placeholder="Insira seu nome"
                                                allowClear
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <span>
                                                <strong>Email</strong>
                                            </span>
                                            <Input
                                                placeholder="Insira seu email"
                                                allowClear
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <span>
                                                <strong>Comentário</strong>
                                            </span>
                                            <TextArea
                                                placeholder="Insira sua menssagem"
                                                allowClear
                                            />
                                        </Form.Item>
                                        <Form.Item style={{ float: "right" }}>
                                            <Button>Enviar</Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                                <BackTop
                                    style={{
                                        backgroundColor: "#289DAA",
                                        borderRadius: "50%",
                                    }}
                                />
                            </Card>
                        )}
                    </Card>
                </Content>
            </Layout>
        </>
    );
}

export default Publicacao;
