/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from "react";
import { Button, Card, Divider, Form, Input, Layout, Row } from "antd";
import logo from "../../assets/imgs/logoPNG.png";
import Swal from "sweetalert2";

import { useAuth } from "../../hooks/auth";
import { LockTwoTone, MailTwoTone } from "@ant-design/icons";

const { Content } = Layout;

interface FormProps {
    email: string;
    senha: string;
}

function Login() {
    const { signIn, loadingAuth } = useAuth();

    const handleLogin = useCallback(
        async ({ email, senha }: FormProps) => {
            signIn({ email, password: senha });
        },
        [signIn]
    );

    const onFinishFailed = (errorInfo: any) => {
        Swal.fire({
            title: "Ocorreu um erro",
            text: "Os campos não podem estarem vazios",
            icon: "error",
            showCloseButton: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
        });
        console.log("Failed:", errorInfo);
    };

    return (
        <Layout>
            <Content>
                <Row
                    justify="space-around"
                    align="middle"
                    style={{
                        padding: 45,
                        height: "auto",
                    }}
                >
                    <Card style={{ padding: 15 }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                src={logo}
                                alt="Logo Janela"
                                style={{ height: "100px" }}
                            />
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <h2
                                style={{
                                    color: "#2D4040",
                                    fontWeight: 600,
                                    paddingTop: 25,
                                }}
                            >
                                Faça login para continuar
                            </h2>
                            <p>
                                Preencha as informações abaixo para entrar no
                                sistema.
                            </p>
                        </div>

                        <Divider />
                        <Form
                            onFinish={handleLogin}
                            initialValues={{ remember: true }}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "O e-mail é obrigatório!",
                                    },
                                ]}
                            >
                                <Input
                                    name="email"
                                    type="text"
                                    size="large"
                                    prefix={<MailTwoTone />}
                                    allowClear
                                    placeholder="Insira seu email"
                                />
                            </Form.Item>
                            <Form.Item
                                name="senha"
                                rules={[
                                    {
                                        required: true,
                                        message: "A senha é obrigatória!",
                                    },
                                ]}
                            >
                                <Input.Password
                                    name="senha"
                                    allowClear
                                    type="password"
                                    prefix={<LockTwoTone />}
                                    required
                                    size="large"
                                    placeholder="Insira sua senha"
                                />
                            </Form.Item>
                            <br />
                            <Form.Item style={{ textAlign: "center" }}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    disabled={loadingAuth}
                                    block
                                    style={{
                                        backgroundColor: "#3E98A4",
                                        color: "#FFFFFF",
                                        borderColor: "#3E98A4",
                                    }}
                                >
                                    Entrar
                                </Button>
                            </Form.Item>

                            <Form.Item style={{ textAlign: "center" }}>
                                <Button
                                    type="dashed"
                                    htmlType="submit"
                                    size="large"
                                    block
                                    href="/signup"
                                >
                                    Criar nova conta
                                </Button>
                            </Form.Item>
                            <Divider />
                            <Form.Item style={{ textAlign: "center" }}>
                                <a href="#">Recuperar Senha</a>
                            </Form.Item>
                        </Form>
                    </Card>
                </Row>
            </Content>
        </Layout>
    );
}

export default Login;
