/* eslint-disable react-hooks/rules-of-hooks */
import { Button, Card, Divider, Form, Input, Layout, Row } from "antd";
import { useCallback } from "react";
import Swal from "sweetalert2";
import { useGlossario } from "../../../hooks/glossario";

const { Content } = Layout;

const { TextArea } = Input;

interface FormProps {
    letra: string;
    palavra: string;
    definicao: string;
    sendTo: string;
}

function handleGlossario() {
    Swal.fire({
        icon: "success",
        title: "Glossário Publicado",
        text: "Consulte a sessão de glossário para identificação",
    });
}

function newGlossario() {
    const { createGlossario } = useGlossario();

    const onFinish = useCallback(
        async ({ letra, palavra, definicao, sendTo }: FormProps) => {
            await createGlossario({
                letra,
                palavra,
                definicao,
                sendTo,
            });
            handleGlossario();
        },
        [createGlossario]
    );

    return (
        <Layout>
            <Content>
                <Row
                    justify="space-around"
                    align="middle"
                    style={{
                        padding: 45,
                    }}
                >
                    <Row
                        justify="space-around"
                        align="middle"
                        style={{ padding: 20 }}
                    >
                        <Card style={{ width: "60vw", padding: 30 }}>
                            <div className="sign-in-title">
                                <h2
                                    style={{
                                        color: "#52a5b0",
                                        fontWeight: 600,
                                        paddingTop: 20,
                                        alignSelf: "start",
                                    }}
                                >
                                    Adicionar Novo Ítem ao Glossário
                                </h2>
                                <p
                                    style={{
                                        alignSelf: "start",
                                        fontWeight: 700,
                                    }}
                                >
                                    Preencha as informações abaixo para
                                    continuar:
                                </p>
                            </div>
                            <Divider />
                            <Form
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                                // onFinishFailed={onFinishFailed}
                            >
                                {/* Adicionar 28 letras do alfabeto */}
                                <strong>
                                    <span
                                        style={{
                                            color: "#F05152",
                                            marginRight: 4,
                                        }}
                                    >
                                        *
                                    </span>
                                    Letra
                                </strong>
                                <Form.Item
                                    name="letra"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Por favor, insira uma letra!",
                                        },
                                    ]}
                                >
                                    <Input
                                        name="letra"
                                        allowClear
                                        size="large"
                                        placeholder="Digite a Letra..."
                                    />
                                </Form.Item>
                                <strong>
                                    <span
                                        style={{
                                            color: "#F05152",
                                            marginRight: 4,
                                        }}
                                    >
                                        *
                                    </span>
                                    Palavra
                                </strong>
                                <Form.Item
                                    name="palavra"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Por favor, insira uma palavra!",
                                        },
                                    ]}
                                >
                                    <Input
                                        name="palavra"
                                        allowClear
                                        size="large"
                                        placeholder="Digite a Palavra..."
                                    />
                                </Form.Item>

                                <strong>
                                    <span
                                        style={{
                                            color: "#F05152",
                                            marginRight: 4,
                                        }}
                                    >
                                        *
                                    </span>
                                    Definição
                                </strong>
                                <Form.Item
                                    name="definicao"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Por favor, insira uma definição!",
                                        },
                                    ]}
                                >
                                    <TextArea
                                        name="definicao"
                                        placeholder="Digite uma definição..."
                                        allowClear
                                    />
                                </Form.Item>

                                <strong>Enviado por</strong>
                                <Form.Item name="sendTo">
                                    <Input
                                        name="sendTo"
                                        allowClear
                                        size="large"
                                        placeholder="Digite um nome..."
                                    />
                                </Form.Item>

                                <Form.Item style={{ float: "right" }}>
                                    <Button
                                        htmlType="submit"
                                        // loading={loading}
                                        type="primary"
                                        size="large"
                                        style={{
                                            backgroundColor: "#3E98A4",
                                            color: "#FFFFFF",
                                            borderColor: "#3E98A4",
                                        }}
                                    >
                                        Criar Glossário
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Row>
                </Row>
            </Content>
        </Layout>
    );
}

export default newGlossario;
