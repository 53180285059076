import { Layout, Image, Divider } from "antd";
import { useLocation } from "react-router-dom";

const { Content } = Layout;

function Perfil() {
    const { state } = useLocation();

    return (
        <>
            <Layout style={{ backgroundColor: "#F0F2F5" }}>
                <Content
                    className="layout"
                    style={{
                        padding: 24,
                        margin: 0,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "970px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                height: "100vh"
                            }}
                        >
                            <Image
                                style={{ borderRadius: "100%" }}
                                width={400}
                                height={400}
                                src={state.authorImage}
                            />
                            <br />
                            <div
                                style={{
                                    textAlign: "center",
                                    maxWidth: "60%",
                                    maxHeight: "60%",
                                }}
                            >
                                <h1
                                    style={{
                                        textTransform: "uppercase",
                                        fontWeight: "700",
                                        lineHeight: 2.0,
                                        letterSpacing: "-.25px",
                                        margin: "20px 0px 1rem",
                                        fontSize: 40
                                    }}
                                >
                                    <strong>{state.autor}</strong>
                                </h1>

                                <p>
                                    {state.bio}
                                </p>
                                <Divider />
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                </Content>
            </Layout>
        </>
    );
}

export default Perfil;
