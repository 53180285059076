/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Layout, Tag, Button, Card, Tabs } from "antd";
import { useHistory } from "react-router-dom";
import { LikeOutlined, MailOutlined, MessageOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { database } from "../../config/firebase";
import "./styles.css";

const { Content } = Layout;
const { TabPane } = Tabs;

function callback(key: any) {
    console.log(key);
}
function Blog() {
    const [user, setUser] = useState({} as any);

    useEffect(() => {
        const currentUser = JSON.parse(
            localStorage.getItem("@janela:user") as string
        );
        setUser(currentUser);
    }, []);

    const history = useHistory();

    interface DataTypePost {
        uid: string;
        autor: string;
        titulo: string;
        subtitulo: string;
        cartolas: string;
        materia: string;
        bio: string;
        imagem: string;
        categoria: string;
        date: string;
        photoCredit: string;
        tempoLeitura: string;
        autorImagem: string;
        favcolor: string;
    }

    let data2;
    const [data, setData] = useState<DataTypePost[]>([]);

    async function getPost() {
        Swal.fire({
            title: "Carregando Matéria...",
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();
                await database
                    .ref()
                    .child("posts")
                    .get()
                    .then((snapshot) => {
                        if (snapshot.exists()) {
                            data2 = snapshot.val();
                            const data3 = Object.entries(data2).map(
                                (d) => d[1]
                            ) as DataTypePost[];
                            setData(data3);
                        } else {
                            console.log("No data available");
                        }
                    })
                    .then(() => {
                        Swal.close();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
        });
    }
    useEffect(() => {
        getPost();
    }, []);
    console.log(data);

    function redirect(d: DataTypePost) {
        console.log(d.materia);
        history.push(`./publicacao`, {
            id: d.uid,
            autor: d.autor,
            title: d.titulo,
            subtitulo: d.subtitulo,
            cartola: d.cartolas,
            bio: d.bio,
            categoria: d.categoria,
            materia: d.materia,
            imagem: d.imagem,
            date: d.date,
            photoCredit: d.photoCredit,
            tempoLeitura: d.tempoLeitura,
            authorImage: d.autorImagem,
            tagColor: d.favcolor,
        });
    }

    return (
        <>
            <Layout style={{ backgroundColor: "#F0F2F5" }}>
                <Content
                    className="layout"
                    style={{
                        padding: 24,

                        margin: 0,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Card style={{ maxWidth: "50%", padding: "10px" }}>
                        <div
                            style={{
                                borderLeft: "14px solid  #63ADB8",
                                height: 30,
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <h1
                                    style={{
                                        paddingLeft: "10px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Blog{" "}
                                </h1>
                                {user && (
                                    <Button type="primary" href="/posts/novo">
                                        Nova Publicação
                                    </Button>
                                )}
                            </div>
                        </div>
                        <br />
                        <br />
                        <div>
                            <p
                                style={{
                                    textAlign: "justify",
                                    textIndent: "1em",
                                }}
                            >
                                Nosso Blog reúne dicas, entrevistas, prévias de
                                lições dos Módulos, e artigos de opinião,
                                separados por categorias.
                                <br /> Explore à vontade e deixe seus
                                comentários.
                            </p>
                            <Tabs defaultActiveKey="0" onChange={callback}>
                                <TabPane tab="TODOS" key="0">
                                    {data.map((d) => (
                                        <Card
                                            id="ant-card"
                                            style={{
                                                backgroundColor: "#EDEDED",
                                                margin: "10px 0",
                                            }}
                                            onClick={() => redirect(d)}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    <Tag
                                                        style={{
                                                            textTransform:
                                                                "uppercase",
                                                            fontSize: "16px",
                                                        }}
                                                        color={d.favcolor}
                                                    >
                                                        {d.categoria}
                                                    </Tag>
                                                    <br />
                                                    <br />
                                                    <h1>{d.titulo}</h1>
                                                    <p>{d.subtitulo}</p>
                                                </div>
                                                <Button
                                                    onClick={() => redirect(d)}
                                                    style={{
                                                        backgroundColor: `${d.favcolor}`,
                                                        color: "#fff",
                                                        border: `${d.favcolor}`,
                                                    }}
                                                >
                                                    Ver Matéria
                                                </Button>
                                            </div>

                                            <div
                                                style={{
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Button
                                                    shape="circle"
                                                    style={{
                                                        marginRight: 10,
                                                        backgroundColor: `${d.favcolor}`,
                                                        borderColor: `${d.favcolor}`,
                                                        color: "#fff",
                                                    }}
                                                    onClick={() => redirect(d)}
                                                >
                                                    <MailOutlined />
                                                </Button>
                                                <Button
                                                    shape="circle"
                                                    style={{
                                                        marginRight: 10,
                                                        backgroundColor: `${d.favcolor}`,
                                                        borderColor: `${d.favcolor}`,
                                                        color: "#fff",
                                                    }}
                                                >
                                                    <MessageOutlined />
                                                </Button>
                                                <Button
                                                    shape="circle"
                                                    style={{
                                                        marginRight: 10,
                                                        backgroundColor: `${d.favcolor}`,
                                                        borderColor: `${d.favcolor}`,
                                                        color: "#fff",
                                                    }}
                                                >
                                                    <LikeOutlined />
                                                </Button>
                                            </div>
                                        </Card>
                                    ))}
                                </TabPane>
                                <TabPane tab="PAUTA" key="1">
                                    {data
                                        .filter((d) => d.categoria === "pauta")
                                        .map((d) => (
                                            <Card
                                                id="ant-card"
                                                style={{
                                                    backgroundColor: "#EDEDED",
                                                    margin: "10px 0",
                                                }}
                                                onClick={() => redirect(d)}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div>
                                                        <Tag
                                                            style={{
                                                                textTransform:
                                                                    "uppercase",
                                                                fontSize:
                                                                    "16px",
                                                            }}
                                                            color={d.favcolor}
                                                        >
                                                            {d.categoria}
                                                        </Tag>
                                                        <br />
                                                        <br />
                                                        <h1>{d.titulo}</h1>
                                                        <p>{d.subtitulo}</p>
                                                    </div>
                                                    <Button
                                                        onClick={() =>
                                                            redirect(d)
                                                        }
                                                        style={{
                                                            backgroundColor: `${d.favcolor}`,
                                                            color: "#fff",
                                                            border: `${d.favcolor}`,
                                                        }}
                                                    >
                                                        Ver Matéria
                                                    </Button>
                                                </div>

                                                <div
                                                    style={{
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Button
                                                        shape="circle"
                                                        style={{
                                                            marginRight: 10,
                                                            backgroundColor: `${d.favcolor}`,
                                                            borderColor: `${d.favcolor}`,
                                                            color: "#fff",
                                                        }}
                                                        onClick={() =>
                                                            redirect(d)
                                                        }
                                                    >
                                                        <MailOutlined />
                                                    </Button>
                                                    <Button
                                                        shape="circle"
                                                        style={{
                                                            marginRight: 10,
                                                            backgroundColor: `${d.favcolor}`,
                                                            borderColor: `${d.favcolor}`,
                                                            color: "#fff",
                                                        }}
                                                    >
                                                        <MessageOutlined />
                                                    </Button>
                                                    <Button
                                                        shape="circle"
                                                        style={{
                                                            marginRight: 10,
                                                            backgroundColor: `${d.favcolor}`,
                                                            borderColor: `${d.favcolor}`,
                                                            color: "#fff",
                                                        }}
                                                    >
                                                        <LikeOutlined />
                                                    </Button>
                                                </div>
                                            </Card>
                                        ))}
                                </TabPane>
                                <TabPane tab="APURAÇÃO" key="2">
                                    {data
                                        .filter(
                                            (d) => d.categoria === "apuracão"
                                        )
                                        .map((d) => (
                                            <Card
                                                id="ant-card"
                                                style={{
                                                    backgroundColor: "#EDEDED",
                                                    margin: "10px 0",
                                                }}
                                                onClick={() => redirect(d)}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div>
                                                        <Tag
                                                            style={{
                                                                textTransform:
                                                                    "uppercase",
                                                                fontSize:
                                                                    "16px",
                                                            }}
                                                            color={d.favcolor}
                                                        >
                                                            {d.categoria}
                                                        </Tag>
                                                        <br />
                                                        <br />
                                                        <h1>{d.titulo}</h1>
                                                        <p>{d.subtitulo}</p>
                                                    </div>
                                                    <Button
                                                        onClick={() =>
                                                            redirect(d)
                                                        }
                                                        style={{
                                                            backgroundColor: `${d.favcolor}`,
                                                            color: "#fff",
                                                            border: `${d.favcolor}`,
                                                        }}
                                                    >
                                                        Ver Matéria
                                                    </Button>
                                                </div>

                                                <div
                                                    style={{
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Button
                                                        shape="circle"
                                                        style={{
                                                            marginRight: 10,
                                                            backgroundColor: `${d.favcolor}`,
                                                            borderColor: `${d.favcolor}`,
                                                            color: "#fff",
                                                        }}
                                                        onClick={() =>
                                                            redirect(d)
                                                        }
                                                    >
                                                        <MailOutlined />
                                                    </Button>
                                                    <Button
                                                        shape="circle"
                                                        style={{
                                                            marginRight: 10,
                                                            backgroundColor: `${d.favcolor}`,
                                                            borderColor: `${d.favcolor}`,
                                                            color: "#fff",
                                                        }}
                                                    >
                                                        <MessageOutlined />
                                                    </Button>
                                                    <Button
                                                        shape="circle"
                                                        style={{
                                                            marginRight: 10,
                                                            backgroundColor: `${d.favcolor}`,
                                                            borderColor: `${d.favcolor}`,
                                                            color: "#fff",
                                                        }}
                                                    >
                                                        <LikeOutlined />
                                                    </Button>
                                                </div>
                                            </Card>
                                        ))}
                                </TabPane>
                                <TabPane tab="PROFISSÃO" key="3">
                                    {data
                                        .filter(
                                            (d) => d.categoria === "profissão"
                                        )
                                        .map((d) => (
                                            <Card
                                                id="ant-card"
                                                style={{
                                                    backgroundColor: "#EDEDED",
                                                    margin: "10px 0",
                                                }}
                                                onClick={() => redirect(d)}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div>
                                                        <Tag
                                                            style={{
                                                                textTransform:
                                                                    "uppercase",
                                                                fontSize:
                                                                    "16px",
                                                            }}
                                                            color={d.favcolor}
                                                        >
                                                            {d.categoria}
                                                        </Tag>
                                                        <br />
                                                        <br />
                                                        <h1>{d.titulo}</h1>
                                                        <p>{d.subtitulo}</p>
                                                    </div>
                                                    <Button
                                                        onClick={() =>
                                                            redirect(d)
                                                        }
                                                        style={{
                                                            backgroundColor: `${d.favcolor}`,
                                                            color: "#fff",
                                                            border: `${d.favcolor}`,
                                                        }}
                                                    >
                                                        Ver Matéria
                                                    </Button>
                                                </div>

                                                <div
                                                    style={{
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Button
                                                        shape="circle"
                                                        style={{
                                                            marginRight: 10,
                                                            backgroundColor: `${d.favcolor}`,
                                                            borderColor: `${d.favcolor}`,
                                                            color: "#fff",
                                                        }}
                                                        onClick={() =>
                                                            redirect(d)
                                                        }
                                                    >
                                                        <MailOutlined />
                                                    </Button>
                                                    <Button
                                                        shape="circle"
                                                        style={{
                                                            marginRight: 10,
                                                            backgroundColor: `${d.favcolor}`,
                                                            borderColor: `${d.favcolor}`,
                                                            color: "#fff",
                                                        }}
                                                    >
                                                        <MessageOutlined />
                                                    </Button>
                                                    <Button
                                                        shape="circle"
                                                        style={{
                                                            marginRight: 10,
                                                            backgroundColor: `${d.favcolor}`,
                                                            borderColor: `${d.favcolor}`,
                                                            color: "#fff",
                                                        }}
                                                    >
                                                        <LikeOutlined />
                                                    </Button>
                                                </div>
                                            </Card>
                                        ))}
                                </TabPane>
                                <TabPane tab="EDIÇÃO" key="4">
                                    {data
                                        .filter((d) => d.categoria === "edicão")
                                        .map((d) => (
                                            <Card
                                                id="ant-card"
                                                style={{
                                                    backgroundColor: "#EDEDED",
                                                    margin: "10px 0",
                                                }}
                                                onClick={() => redirect(d)}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div>
                                                        <Tag
                                                            style={{
                                                                textTransform:
                                                                    "uppercase",
                                                                fontSize:
                                                                    "16px",
                                                            }}
                                                            color={d.favcolor}
                                                        >
                                                            {d.categoria}
                                                        </Tag>
                                                        <br />
                                                        <br />
                                                        <h1>{d.titulo}</h1>
                                                        <p>{d.subtitulo}</p>
                                                    </div>
                                                    <Button
                                                        onClick={() =>
                                                            redirect(d)
                                                        }
                                                        style={{
                                                            backgroundColor: `${d.favcolor}`,
                                                            color: "#fff",
                                                            border: `${d.favcolor}`,
                                                        }}
                                                    >
                                                        Ver Matéria
                                                    </Button>
                                                </div>

                                                <div
                                                    style={{
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Button
                                                        shape="circle"
                                                        style={{
                                                            marginRight: 10,
                                                            backgroundColor: `${d.favcolor}`,
                                                            borderColor: `${d.favcolor}`,
                                                            color: "#fff",
                                                        }}
                                                        onClick={() =>
                                                            redirect(d)
                                                        }
                                                    >
                                                        <MailOutlined />
                                                    </Button>
                                                    <Button
                                                        shape="circle"
                                                        style={{
                                                            marginRight: 10,
                                                            backgroundColor: `${d.favcolor}`,
                                                            borderColor: `${d.favcolor}`,
                                                            color: "#fff",
                                                        }}
                                                    >
                                                        <MessageOutlined />
                                                    </Button>
                                                    <Button
                                                        shape="circle"
                                                        style={{
                                                            marginRight: 10,
                                                            backgroundColor: `${d.favcolor}`,
                                                            borderColor: `${d.favcolor}`,
                                                            color: "#fff",
                                                        }}
                                                    >
                                                        <LikeOutlined />
                                                    </Button>
                                                </div>
                                            </Card>
                                        ))}
                                </TabPane>
                                <TabPane tab="OPINIÃO" key="5">
                                    {data
                                        .filter(
                                            (d) => d.categoria === "opinião"
                                        )
                                        .map((d) => (
                                            <Card
                                                id="ant-card"
                                                style={{
                                                    backgroundColor: "#EDEDED",
                                                    margin: "10px 0",
                                                }}
                                                onClick={() => redirect(d)}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div>
                                                        <Tag
                                                            style={{
                                                                textTransform:
                                                                    "uppercase",
                                                                fontSize:
                                                                    "16px",
                                                            }}
                                                            color={d.favcolor}
                                                        >
                                                            {d.categoria}
                                                        </Tag>
                                                        <br />
                                                        <br />
                                                        <h1>{d.titulo}</h1>
                                                        <p>{d.subtitulo}</p>
                                                    </div>
                                                    <Button
                                                        onClick={() =>
                                                            redirect(d)
                                                        }
                                                        style={{
                                                            backgroundColor: `${d.favcolor}`,
                                                            color: "#fff",
                                                            border: `${d.favcolor}`,
                                                        }}
                                                    >
                                                        Ver Matéria
                                                    </Button>
                                                </div>

                                                <div
                                                    style={{
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Button
                                                        shape="circle"
                                                        style={{
                                                            marginRight: 10,
                                                            backgroundColor: `${d.favcolor}`,
                                                            borderColor: `${d.favcolor}`,
                                                            color: "#fff",
                                                        }}
                                                        onClick={() =>
                                                            redirect(d)
                                                        }
                                                    >
                                                        <MailOutlined />
                                                    </Button>
                                                    <Button
                                                        shape="circle"
                                                        style={{
                                                            marginRight: 10,
                                                            backgroundColor: `${d.favcolor}`,
                                                            borderColor: `${d.favcolor}`,
                                                            color: "#fff",
                                                        }}
                                                    >
                                                        <MessageOutlined />
                                                    </Button>
                                                    <Button
                                                        shape="circle"
                                                        style={{
                                                            marginRight: 10,
                                                            backgroundColor: `${d.favcolor}`,
                                                            borderColor: `${d.favcolor}`,
                                                            color: "#fff",
                                                        }}
                                                    >
                                                        <LikeOutlined />
                                                    </Button>
                                                </div>
                                            </Card>
                                        ))}
                                </TabPane>
                            </Tabs>
                        </div>
                    </Card>
                </Content>
            </Layout>
        </>
    );
}

export default Blog;
