import React, { createContext, useCallback, useState, useContext } from "react";
import Swal from "sweetalert2";

import { useHistory } from "react-router-dom";
import { database } from "../config/firebase";

interface GlossarioProps {
    letra: string;
    palavra: string;
    definicao: string;
    sendTo: string;
}

interface GlossarioContextData {
    Glossario: GlossarioProps[];
    loadingGlossario: boolean;
    getAll(usersUID: string): Promise<void>;
    createGlossario(GlossarioData: GlossarioProps): Promise<void>;
}

const GlossarioContext = createContext<GlossarioContextData>(
    {} as GlossarioContextData
);

const GlossarioProviders: React.FC = ({ children }: any) => {
    const { push } = useHistory();
    const [GlossarioData, setGlossarioData] = useState([] as GlossarioProps[]);
    const [loadingGlossario, setloadingGlossario] = useState(false);

    const getAll = useCallback(async (uid) => {
        setloadingGlossario(true);
        Swal.fire({
            title: "Carregando Glossário...",
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();
                database
                    .ref()
                    .child(`/glossario/${uid}`)
                    .once("value")
                    .then(async (snapshot) => {
                        const list: any[] = [];
                        snapshot.forEach((current) => {
                            list.push(current.val());
                        });
                        setGlossarioData(list as GlossarioProps[]);
                        Swal.close();
                    })
                    .catch(() => {
                        Swal.hideLoading();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: `Aconteceu algum erro inesperado.\nEntre em contato com o suporte técnico.`,
                        });
                    })
                    .finally(() => {
                        setloadingGlossario(false);
                    });
            },
        });
    }, []);

    const createGlossario = useCallback(
        async ({ letra, palavra, definicao, sendTo }) => {
            setloadingGlossario(true);
            Swal.fire({
                title: "Cadastrando...",
                showConfirmButton: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: async () => {
                    Swal.showLoading();
                    const uid = database.ref().push().key;

                    await database
                        .ref()
                        .child(`glosssario/${uid}`)
                        .set({
                            uid,
                            letra,
                            palavra,
                            definicao,
                            sendTo,
                        })
                        .then(() => {
                            Swal.fire({
                                icon: "success",
                                title: "Cadastro de Post",
                                text: `Publicação adicionada com Sucesso`,
                                showConfirmButton: false,
                                timer: 1800,
                            }).then(() => push("/glossario"));
                            Swal.close();
                        })
                        .catch(() => {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Aconteceu algum erro inesperado.\nEntre em contato com o suporte técnico.",
                            });
                        })
                        .finally(() => setloadingGlossario(false));
                },
            });
        },
        [push]
    );
    return (
        <GlossarioContext.Provider
            value={{
                getAll,
                createGlossario,
                Glossario: GlossarioData,
                loadingGlossario
            }}
        >
            {children}
        </GlossarioContext.Provider>
    );
};

function useGlossario(): GlossarioContextData {
    const context = useContext(GlossarioContext);

    if (!context) {
        throw new Error("usePost must be used within an AuthProvider");
    }

    return context;
}

export { GlossarioProviders, useGlossario };
