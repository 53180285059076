import React, { createContext, useCallback, useState, useContext } from "react";
import Swal from "sweetalert2";

import { useHistory } from "react-router-dom";
import { auth, database } from "../config/firebase";

interface ProfileProps {
    uid?: string;
    name: string;
    email: string;
    password: string;
    age: number;
    state: string;
    city: string;
    other?: string;
    profile: string;
    profileImagem: string;
}

interface ProfileContextData {
    Profiles: ProfileProps[];
    loadingProfiles: boolean;
    getAll(usersUID: string): Promise<void>;
    createProfiles(ProfilesData: ProfileProps): Promise<void>;
}

const ProfilesContext = createContext<ProfileContextData>(
    {} as ProfileContextData
);

const ProfilesProvider: React.FC = ({ children }: any) => {
    const { push } = useHistory();
    const [ProfilessData, setProfiless] = useState([] as ProfileProps[]);
    const [loadingProfiles, setLoadingProfiles] = useState(false);

    const getAll = useCallback(async (uid) => {
        setLoadingProfiles(true);

        Swal.fire({
            title: "CarregandoPerfil...",
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();
                database
                    .ref()
                    .child(`/users/${uid}`)
                    .once("value")
                    .then(async (snapshot) => {
                        const list: any[] = [];
                        snapshot.forEach((current) => {
                            list.push(current.val());
                        });
                        setProfiless(list as ProfileProps[]);
                        Swal.close();
                    })
                    .catch(() => {
                        Swal.hideLoading();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: `Aconteceu algum erro inesperado.\nEntre em contato com o suporte técnico.`,
                        });
                    })
                    .finally(() => {
                        setLoadingProfiles(false);
                    });
            },
        });
    }, []);

    const createProfiles = useCallback(
        async ({ name, email, password, age, city, other, profile, profileImagem,state }) => {
            setLoadingProfiles(true);
            Swal.fire({
                title: "Cadastrando...",
                showConfirmButton: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: async () => {
                    Swal.showLoading();
                    // const userUID = JSON.parse(
                    //     localStorage.getItem("@janela:user") as string
                    // );
                    // console.log("user", userUID);
                    await auth
                        .createUserWithEmailAndPassword(email, password)
                        .then(async (res) => {
                            const uid = res.user?.uid;
                            await res.user?.updateProfile({
                                displayName: name,
                            });
                         
                            await database.ref().child(`users/${uid}`).set({
                                uid,
                                name,
                                email,
                                password,
                                age,
                                state,
                                city,
                                other,
                                profile,
                                profileImagem
                            });
                        })
                        .then((res) => {
                            Swal.fire({
                                icon: "success",
                                title: "Cadastro de Profissional",
                                text: `Profissional cadastrado com sucesso!`,
                                showConfirmButton: false,
                                timer: 1800,
                            }).then(() => push("/home"));
                            Swal.close();
                        })
                        .catch(() => {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Aconteceu algum erro inesperado.\nEntre em contato com o suporte técnico.",
                            });
                        })
                        .finally(() => setLoadingProfiles(false));
                },
            });
        },
        [push]
    );
    return (
        <ProfilesContext.Provider
            value={{
                getAll,
                createProfiles,
                Profiles: ProfilessData,
                loadingProfiles,
            }}
        >
            {children}
        </ProfilesContext.Provider>
    );
};

function useProfiles(): ProfileContextData {
    const context = useContext(ProfilesContext);

    if (!context) {
        throw new Error("useProfiles must be used within an AuthProvider");
    }

    return context;
}

export { ProfilesProvider, useProfiles };
