/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import {
    Layout,
    Form,
    Input,
    Card,
    Button,
    Tooltip,
    Divider,
    Image,
} from "antd";

import {
    LinkedinFilled,
    InstagramOutlined,
    LinkOutlined,
    BehanceOutlined,
} from "@ant-design/icons";

import Image01 from "../../assets/imgs/users/1.png";
import Image02 from "../../assets/imgs/users/2.jpeg";
import Image03 from "../../assets/imgs/users/3.jpeg";
import Image04 from "../../assets/imgs/users/4.jpeg";
import Image05 from "../../assets/imgs/users/5.jpg";
import Image06 from "../../assets/imgs/users/6.jpg";
const { Content } = Layout;

const { TextArea } = Input;

function Editorial() {
    return (
        <>
            <Layout>
                <Content
                    className="layout"
                    style={{
                        padding: 30,
                        margin: 0,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Card
                        style={{
                            maxWidth: "50%",
                            fontSize: "1.8rem",
                            textIndent: "1em",
                            textAlign: "justify",
                            padding: "15px",
                        }}
                    >
                        <div
                            style={{
                                borderLeft: "14px solid  #2E8B9A",
                                height: 30,
                            }}
                        >
                            <h1
                                style={{
                                    fontWeight: "bolder",
                                    paddingLeft: "10px",
                                }}
                            >
                                <Tooltip title="O editorial é um texto opinativo que expressa a visão de mundo e ideologia de um veículo jornalístico. ">
                                    Editorial
                                </Tooltip>
                            </h1>
                        </div>
                        <Divider />

                        <p>
                            O jornalismo é complexo, repleto de escolhas,
                            atravessa dilemas diários e é feito por muita gente.
                            Gente que investiga, grava, edita, se emociona, abre
                            mão dos fins de semana, coloca a própria vida em
                            risco. E começa tudo de novo no dia seguinte. Mas
                            poucas pessoas sabem disso. Só vemos o repórter na
                            televisão, a matéria publicada, o produto final.
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            Ainda há um muro entre esse trabalho e um público
                            cada vez mais curioso, participativo e desconfiado.
                            Precisamos de mais aberturas. O JANELA está aqui por
                            isso. Acreditamos que compreender como o jornalismo
                            é feito ajuda pessoas a fazer melhores escolhas na
                            hora de se informar, a identificar uma reportagem
                            bem apurada e a criticar o jornalismo para
                            melhorá-lo, sem descredibilizá-lo.
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            Aqui, te convidamos a fazer um percurso por quatro
                            aspectos que consideramos fundamentais no
                            jornalismo: Pauta, Apuração, Edição e Profissão —
                            nossos módulos de conteúdo. Vamos te mostrar como
                            acontece uma reunião de pauta, contar os bastidores
                            de apuração de coberturas marcantes no Ceará,
                            entender a construção de pontos de vista no
                            jornalismo e saber mais sobre as condições
                            trabalhistas desta profissão hoje.
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            Nossa linha editorial é orientada pela
                            transparência, didática e pluralidade. Defendemos um
                            jornalismo que explique suas escolhas, abra seus
                            processos e reflita sobre a acessibilidade da sua
                            linguagem. Esperamos que ao final do percurso pelo
                            JANELA, você tenha mais perguntas para fazer.
                            Queremos te ajudar a pensar sobre cada uma delas.
                            Então, pode entrar, e fique à vontade. As portas (e
                            janelas) estarão sempre abertas.
                        </p>
                        <br />
                        <div
                            style={{
                                borderLeft: "14px solid  #2E8B9A",
                                height: 30,
                            }}
                        >
                            <h1
                                style={{
                                    fontWeight: "bolder",
                                    paddingLeft: "10px",
                                }}
                            >
                                <Tooltip title="O editorial é um texto opinativo que expressa a visão de mundo e ideologia de um veículo jornalístico. ">
                                    Mapa do Site
                                </Tooltip>
                            </h1>
                        </div>
                        <Divider />
                        <p>
                            Saiba como navegar pelo JANELA e conheça um pouco
                            dos nossos bastidores.
                        </p>
                        <br />

                        <p style={{ textAlign: "center" }}>
                            <div style={{ width: "100%" }}>
                                <div
                                    style={{
                                        position: "relative",
                                        paddingBottom: "56.25%",
                                        paddingTop: 0,
                                        height: 0,
                                    }}
                                >
                                    <iframe
                                        frameBorder="0"
                                        width="1200px"
                                        height="675px"
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "100%",
                                        }}
                                        src="https://view.genial.ly/61f6f53b2fab740018e22b92"
                                    ></iframe>{" "}
                                </div>{" "}
                            </div>
                        </p>
                        <br />
                        <br />

                        <div
                            style={{
                                borderLeft: "14px solid #2E8B9A",
                                height: 30,
                            }}
                        >
                            <h1
                                style={{
                                    fontWeight: "bolder",
                                    paddingLeft: "10px",
                                }}
                            >
                                Expediente
                            </h1>
                        </div>
                        <p style={{ textAlign: "justify", paddingTop: "20px" }}>
                            É no expediente que encontramos a equipe de um
                            veículo jornalístico, bem como suas atividades
                            desempenhadas.
                        </p>

                        <br />
                        <br />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Image
                                style={{
                                    borderRadius: "50%",
                                    height: 200,
                                    width: 200,
                                }}
                                src={Image02}
                            />
                            <br />
                            <br />

                            <div style={{ maxWidth: 800, paddingRight: 100 }}>
                                <h1>Laís Oliveira</h1>
                                <p>
                                    Produção e reportagem Estudante de
                                    jornalismo da Universidade Federal do Ceará
                                    (UFC). <br />
                                    <br /> Realizou pesquisas, entrevistas,
                                    redação de textos, produção de roteiros e
                                    elaboração de recursos digitais.
                                </p>
                                <Button
                                    shape="circle"
                                    size="large"
                                    icon={<LinkedinFilled />}
                                    href="https://www.linkedin.com/in/lais-oliveira-76153b146/"
                                    target="_blank"
                                    type="primary"
                                ></Button>
                            </div>
                        </div>
                        <br />
                        <br />

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Image
                                style={{
                                    borderRadius: "50%",
                                    height: 200,
                                    width: 200,
                                }}
                                src={Image03}
                            />
                            <br />
                            <br />

                            <div style={{ maxWidth: 800, paddingRight: 100 }}>
                                <h1>Rafael Rodrigues</h1>
                                <p>
                                    Orientação e edição Professor do curso de
                                    jornalismo da Universidade Federal do Ceará
                                    (UFC). <br /> <br /> Conversou sobre pautas,
                                    fontes, recursos, texto e outros conteúdos,
                                    sugerindo implementações e melhores
                                    direcionamentos.
                                </p>
                                <Button
                                    shape="circle"
                                    size="large"
                                    icon={<LinkOutlined />}
                                    href="http://buscatextual.cnpq.br/buscatextual/visualizacv.do?id=K4164888T4&tokenCaptchar=03AGdBq26cZV8LZ8BJL1ShSQGZ_J2PFd1-K0YrEl7_yWSyw8ijxAA0JLkGyS4g13hIlp4Li6VMo6fO9tg4uXg6S3qRvMB0FHhZZ6DCIK84Rrd_LN6PzYPqBVNqE2ucG4a1LDaYU7XyU7dgMNYABPuOzsbxoTJ3TyUT7VFk4VRQfBRZu7siLUGIKV9dzltCxzvlgUvjIhOQ93Gu0LiTN9cuZwZRQEuZfBVpk6WCzW0oj9SmVeckuGoM6uDy1CxK4fINr3rn0UTWBtrkxAA4xEpJHBlxKtiJ5YT_WAo0PEw2tcDhWrZ7-wv40mELLGPdh9xzHG2Kf3g7A5Tvhcc6Prijf8Bq8Kdf4v9AajWPvVnUw2rHwNnznkOMYhIwwvxpaMhTwO-pxHs_oIsX2D0FfAG-iJXVFkaIslkTBRPls_q0eAF2PD37pWM-IAwKukGmqut8mzmIk2jmWQEXN9II4e1uMr8VPgQRN_TTdZw5R0eD0HGal9oneuCvmqMnvBtF7BOsLf56ILv521KZSz5uBoqftqbGwqOi3ZyeyA"
                                    target="_blank"
                                    type="primary"
                                ></Button>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Image
                                style={{
                                    borderRadius: "50%",
                                    height: 200,
                                    width: 200,
                                }}
                                src={Image01}
                            />
                            <br />
                            <br />

                            <div style={{ maxWidth: 800, paddingRight: 100 }}>
                                <h1>Container Digital</h1>
                                <p>
                                    Identidade Visual Empresa Júnior do Curso de
                                    Sistemas e Mídias Digitais da Universidade
                                    Federal do Ceará (UFC).
                                    <br />
                                    <br /> Desenvolveu logo e pensou nas cores,
                                    nas fontes e nas formas que são a cara do
                                    JANELA.
                                </p>
                                <Button
                                    shape="circle"
                                    size="large"
                                    icon={<InstagramOutlined />}
                                    href="https://www.instagram.com/codijr/"
                                    target="_blank"
                                    type="primary"
                                ></Button>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Image
                                style={{
                                    borderRadius: "50%",
                                    height: 200,
                                    width: 200,
                                }}
                                src={Image05}
                            />
                            <br />
                            <br />

                            <div style={{ maxWidth: 800, paddingRight: 100 }}>
                                <h1>Lucas Silva</h1>
                                <p>
                                    Desenvolvedor Frontend Pleno (UFC). Bacharel
                                    em Design Digital pela Universidade Federal
                                    do Ceará. <br /> <br /> Desenvolvedor
                                    Frontend pleno no Projeto de Transformação
                                    Digital do Governo do Estado do Ceará. Atuou
                                    no desenvolvimento do site para abrigar o
                                    JANELA.
                                </p>
                                <Button
                                    shape="circle"
                                    size="large"
                                    icon={<LinkedinFilled />}
                                    href="https://br.linkedin.com/in/lucas-silva-6032b1123"
                                    target="_blank"
                                    type="primary"
                                ></Button>
                            </div>
                        </div>
                        <br />
                        <br />

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Image
                                style={{
                                    borderRadius: "50%",
                                    height: 200,
                                    width: 200,
                                }}
                                src={Image04}
                            />
                            <br />
                            <br />

                            <div style={{ maxWidth: 800, paddingRight: 100 }}>
                                <h1>Gabriel Lopes</h1>
                                <p>
                                    Edição audiovisual Editor de imagem e som e
                                    motion designer (animação, ilustração e
                                    design). Trabalhou na edição e animação de
                                    vídeos.
                                </p>
                                <Button
                                    shape="circle"
                                    size="large"
                                    icon={<BehanceOutlined />}
                                    href="behance.com/gabriellopesm"
                                    target="_blank"
                                    type="primary"
                                ></Button>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Image
                                style={{
                                    borderRadius: "50%",
                                    height: 200,
                                    width: 200,
                                }}
                                src={Image06}
                            />
                            <br />
                            <br />

                            <div style={{ maxWidth: 800, paddingRight: 100 }}>
                                <h1>Beatriz Rabelo</h1>
                                <p>
                                    Diagramação de recursos Jornalista pela
                                    Universidade Federal do Ceará (UFC), com
                                    interesse em fotografia. Como Trabalho de
                                    Conclusão de Curso (TCC), apresentou o
                                    projeto transmídia "Atravessamentos Urbanos:
                                    Olhares cinematográficos sobre Fortaleza".
                                    Organizou conteúdos de recursos didáticos
                                    para o site.
                                </p>
                                <Button
                                    shape="circle"
                                    size="large"
                                    icon={<LinkOutlined />}
                                    href="http://buscatextual.cnpq.br/buscatextual/visualizacv.do?id=K8831115H6&tokenCaptchar=03AGdBq25F4UZlQmNyHvBTQHr9uo6G7sumNgTvWN98agbZZ2Z60kJ-K_ctzBS4A4XvnUR9bSrNy6JBQAxS5jG248zTWej0XneJQOw3oQxZ6z2F5PmWYrEFkmOwpc56YFjSG9DDSCBLtJh5JSXdEXfN0JYZqtwgzDe-KMAhC6WKbY3JcuP-EDMKysjpQa8UqNZu1gbPeJvBU8A2DYi_KHFdIN56RSxM5yyhOG8_7SKW_u7HKyaVRYgwPdqIEC_iHvR_AvstSPQZba_J5YI8jbi7Oq70NdSUZO9TO-BJqZShUZXm0HxVv3YkdtmaWPCL1tOfFfZLDbxCv3V--jBbofq07_7Tv_ue_gzbYtX-DpeuPmeAXAKbZag0LiMDxDnedo52vz6Yd_Gd4N9elMUrXUmH5Y24ZqiouqS2OVp9ssgWB6wXTa67sJvbI2hDGtVtFht3fHyG6pg1VYhQfsMOJtr6EBX65vG4D2MxAAYgowZznOMGAR5ru3MKJExxcBsi64XbGa-DExojHGaV0bHoiP_0cJ6wcjMEOmi_lFpYrICkCubby2UYsvHH_Vs"
                                    target="_blank"
                                    type="primary"
                                ></Button>
                            </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <div
                            style={{
                                borderLeft: "14px solid #2E8B9A",
                                height: 30,
                            }}
                        >
                            <h1
                                style={{
                                    fontWeight: "bolder",
                                    paddingLeft: "10px",
                                }}
                            >
                                Agradecimentos
                            </h1>
                        </div>
                        <p style={{ textAlign: "justify", paddingTop: "20px" }}>
                            Aos jornalistas que contribuíram para que o JANELA
                            se tornasse possível.
                        </p>
                        <br />
                        <br />
                        <Card>
                            <div
                                style={{
                                    borderLeft: "14px solid #2E8B9A",
                                    height: 30,
                                }}
                            >
                                <h1
                                    style={{
                                        fontWeight: "bolder",
                                        paddingLeft: "10px",
                                    }}
                                >
                                    Deixe seu comentário
                                </h1>
                                <Form>
                                    <Form.Item>
                                        <span>
                                            <strong>Nome</strong>
                                        </span>
                                        <Input
                                            placeholder="Insira seu nome"
                                            allowClear
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <span>
                                            <strong>Email</strong>
                                        </span>
                                        <Input
                                            placeholder="Insira seu email"
                                            allowClear
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <span>
                                            <strong>Comentário</strong>
                                        </span>
                                        <TextArea
                                            placeholder="Insira sua menssagem"
                                            allowClear
                                        />
                                    </Form.Item>
                                    <Form.Item style={{ float: "right" }}>
                                        <Button>Enviar</Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Card>
                    </Card>
                </Content>
            </Layout>
        </>
    );
}

export default Editorial;
