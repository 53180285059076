/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Layout, Card, Tabs } from "antd";

const { Content } = Layout;
const { TabPane } = Tabs;

function callback(key: any) {
    console.log(key);
}
function Mapas() {

    return (
        <>
            <Layout style={{ backgroundColor: "#F0F2F5" }}>
                <Content
                    className="layout"
                    style={{
                        padding: 24,

                        margin: 0,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Card style={{ maxWidth: "70%" }}>
                        <div
                            style={{
                                borderLeft: "14px solid  #2E8B9A",
                                height: 30,
                            }}
                        >
                            <h1
                                style={{
                                    paddingLeft: "10px",
                                    fontWeight: "bold",
                                    fontFamily: "Ebrima",
                                }}
                            >
                                Mapas Mentais
                            </h1>
                        </div>
                        <br />
                        <br />
                        <div>
                            <p style={{ textAlign: "justify" }}>
                                Aqui você encontra recursos didáticos para
                                exercitar um olhar mais crítico sobre as
                                informações e o jornalismo.
                                <br /> Baixe, compartilhe e use à vontade.
                            </p>
                            <Tabs defaultActiveKey="1" onChange={callback}>
                                <TabPane tab="" key="1">
                                    <Card
                                        style={{ backgroundColor: "#EDEDED" }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div>
                                                <iframe
                                                    width="800"
                                                    height="700"
                                                    src="https://firebasestorage.googleapis.com/v0/b/janela-app-386a2.appspot.com/o/pdfs%2Farquivo.pdf?alt=media&token=e4e79a67-b316-416a-8338-18ccc1b872c5"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                    <br />
                                    <br />
                                    <Card
                                        style={{ backgroundColor: "#EDEDED" }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div>
                                                <iframe
                                                    width="800"
                                                    height="700"
                                                    src="https://firebasestorage.googleapis.com/v0/b/janela-app-386a2.appspot.com/o/pdfs%2F2%20-%20Escala%20de%20Credibilidade.pdf?alt=media&token=00c4b1dd-809f-4e83-8313-5989b580568d"
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Card>
                </Content>
            </Layout>
        </>
    );
}

export default Mapas;
