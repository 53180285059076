import React, { createContext, useCallback, useState, useContext } from "react";
import Swal from "sweetalert2";

import { useHistory } from "react-router-dom";
import { database } from "../config/firebase";

export interface PostProps {
    imagem: string;
    autor: string;
    titulo: string;
    subtitulo: string;
    cartolas: string;
    materia: string;
    bio: string;
    categoria: string;
    date: string;
    photoCredit: string;
    autorImagem: string;
    tempoLeitura: string;
    favcolor: string;
}

interface PostContextData {
    Posts: PostProps[];
    loadingPosts: boolean;
    getAll(usersUID: string): Promise<void>;
    createPost(PostData: PostProps): Promise<void>;
}

const PostContext = createContext<PostContextData>({} as PostContextData);

const PostProviders: React.FC = ({ children }: any) => {
    const { push } = useHistory();
    const [PostsData, setPostsData] = useState([] as PostProps[]);
    const [loadingPosts, setloadingPosts] = useState(false);

    const getAll = useCallback(async (uid) => {
        setloadingPosts(true);

        Swal.fire({
            title: "Carregando...",
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();
                database
                    .ref()
                    .child(`/posts/${uid}`)
                    .once("value")
                    .then(async (snapshot) => {
                        const list: any[] = [];
                        snapshot.forEach((current) => {
                            list.push(current.val());
                        });
                        setPostsData(list as PostProps[]);
                        Swal.close();
                    })
                    .catch(() => {
                        Swal.hideLoading();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: `Aconteceu algum erro inesperado.\nEntre em contato com o suporte técnico.`,
                        });
                    })
                    .finally(() => {
                        setloadingPosts(false);
                    });
            },
        });
    }, []);

    const createPost = useCallback(
        async ({
            autor,
            titulo,
            subtitulo,
            cartolas,
            materia,
            bio,
            categoria,
            imagem,
            date,
            photoCredit,
            autorImagem,
            tempoLeitura,
            favcolor,
        }) => {
            setloadingPosts(true);
            Swal.fire({
                title: "Cadastrando...",
                showConfirmButton: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: async () => {
                    Swal.showLoading();
                    const uid = database.ref().push().key;

                    await database
                        .ref()
                        .child(`posts/${uid}`)
                        .set({
                            uid,
                            autor,
                            titulo,
                            subtitulo,
                            cartolas,
                            materia,
                            bio,
                            categoria,
                            imagem,
                            date,
                            photoCredit,
                            autorImagem,
                            tempoLeitura,
                            favcolor
                        })
                        .then(() => {
                            Swal.fire({
                                icon: "success",
                                title: "Cadastro de Post",
                                text: `Publicação adicionada com Sucesso`,
                                showConfirmButton: false,
                                timer: 1800,
                            }).then(() => push("/blog"));
                            Swal.close();
                        })
                        .catch(() => {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Aconteceu algum erro inesperado.\nEntre em contato com o suporte técnico.",
                            });
                        })
                        .finally(() => setloadingPosts(false));
                },
            });
        },
        [push]
    );
    return (
        <PostContext.Provider
            value={{
                getAll,
                createPost,
                Posts: PostsData,
                loadingPosts,
            }}
        >
            {children}
        </PostContext.Provider>
    );
};

function usePost(): PostContextData {
    const context = useContext(PostContext);

    if (!context) {
        throw new Error("usePost must be used within an AuthProvider");
    }

    return context;
}

export { PostProviders, usePost };
