import {
    EditOutlined,
    EllipsisOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import { Layout, Row, PageHeader, Button, Descriptions, Card, Col } from "antd";

const { Content } = Layout;

function ListPosts() {
    return (
        <Layout>
            <Content>
                <Row
                    justify="space-around"
                    align="middle"
                    style={{
                        padding: 45,
                    }}
                >
                    <Row
                        justify="space-around"
                        align="middle"
                        style={{ padding: 20 }}
                    >
                        <PageHeader
                            ghost={false}
                            title="Lista de Publicações"
                            subTitle="Matérias Publicadas"
                            extra={[
                                <Button
                                    key="3"
                                    type="primary"
                                    style={{
                                        backgroundColor: "#308E9B",
                                        borderColor: "#308E9B",
                                    }}
                                >
                                    Atualizar
                                </Button>,
                            ]}
                        >
                            <Descriptions size="small" column={3}>
                                <Descriptions.Item label="Criado por:">
                                    Laís Oliveira
                                </Descriptions.Item>
                            </Descriptions>
                        </PageHeader>
                        <Card
                            style={{
                                width: "100%",
                                padding: 30,
                                height: "100%",
                            }}
                        >
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Card
                                        title="Matéria 01"
                                        actions={[
                                            <SettingOutlined key="setting" />,
                                            <EditOutlined key="edit" />,
                                            <EllipsisOutlined key="ellipsis" />,
                                        ]}
                                    >
                                        Card content
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card
                                        title="Matéria 02"
                                        actions={[
                                            <SettingOutlined key="setting" />,
                                            <EditOutlined key="edit" />,
                                            <EllipsisOutlined key="ellipsis" />,
                                        ]}
                                    >
                                        Card content
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card
                                        title="Matéria 03"
                                        actions={[
                                            <SettingOutlined key="setting" />,
                                            <EditOutlined key="edit" />,
                                            <EllipsisOutlined key="ellipsis" />,
                                        ]}
                                    >
                                        Card content
                                    </Card>
                                </Col>
                            </Row>
                            <br />
                        </Card>
                    </Row>
                </Row>
            </Content>
        </Layout>
    );
}

export default ListPosts;
