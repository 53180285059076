import { Route, Switch } from "react-router-dom";
import Login from "../login";
import Signup from "../signup";
import Home from "../home";
import Error from "../error";
import Blog from "../blog";
import Publicacao from "../blog/publicacao";
import Maps from "../maps";
import videos from "../videos"
import listPosts from "../posts/listPosts";
import NewPost from "../posts/newPost";
import newGlossario from "../glossario/newGlossario";
import Editorial from "../editorial";

import detalheLicao from "../modulos/detalhes";
import licoes from "../modulos";
import perfil from "../perfil/index";
import Glossario from "../glossario/visualizar";

function Routes() {
    return (
        <Switch>
            <Route path="/login"  component={Login} />
            <Route path="/perfil" component={perfil} />
            <Route path="/signup" component={Signup} />
            <Route path="/" exact component={Home} />
            <Route path="/blog" component={Blog} />
            <Route path="/publicacao" component={Publicacao} />
            <Route path="/mapas" component={Maps} />
            <Route path="/video" component={videos}/>
            <Route path="/posts/novo" component={NewPost} />
            <Route path="/glossario/novo" component={newGlossario} />
            <Route path="/glossario" component={Glossario}/>
            <Route path="/listar" component={listPosts} />
            <Route path="/licoes" component={licoes} />
            <Route path="/licao/1" component={detalheLicao} />
            <Route path="/editorial" component={Editorial} />
            <Route path="*" component={Error} />
        </Switch>
    );
}
export default Routes;
