/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Layout, Card, Tooltip, Divider } from "antd";

const { Content } = Layout;

function Glossario() {
    return (
        <>
            <Layout>
                <Content
                    className="layout"
                    style={{
                        padding: 30,
                        margin: 0,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Card
                        style={{
                            maxWidth: "50%",
                            fontSize: "1.8rem",
                            textIndent: "1em",
                            textAlign: "justify",
                            padding: "15px",
                        }}
                    >
                        <div
                            style={{
                                borderLeft: "14px solid  #2E8B9A",
                                height: 30,
                            }}
                        >
                            <Tooltip title="O editorial é um texto opinativo que expressa a visão de mundo e ideologia de um veículo jornalístico. ">
                                <h1 style={{ fontWeight: "bold" }}>
                                    Glossário
                                </h1>
                            </Tooltip>
                        </div>
                        <Divider />

                        <p>
                            Estamos construindo um Glossário com expressões e
                            palavras do dia a dia dos jornalistas. Você também
                            pode colaborar enviando sugestões de termos e
                            definições.
                        </p>
                        <br />
                        <br />
                        <div>
                            <div
                                style={{
                                    borderLeft: "14px solid  #2E8B9A",
                                    height: 30,
                                }}
                            >
                                <h1 style={{ fontWeight: "bold" }}>A</h1>
                            </div>
                            <br />
                            <strong style={{ padding: "20px" }}>
                                ASSESSORIA DE COMUNICAÇÃO/IMPRENSA
                            </strong>

                            <p style={{ textAlign: "justify" }}>
                                Faz a ponte entre repórteres e fontes como
                                empresas, órgãos públicos, privados, entre
                                outros. <br />
                                Fornece informações à imprensa, divulga notas
                                oficiais e marca entrevistas.
                            </p>

                            <strong style={{ padding: "20px" }}>
                                APURAÇÃO
                            </strong>

                            <p>
                                Processo de investigação jornalística que passa
                                por etapas como pesquisa, entrevistas, checagem
                                e documentação.
                            </p>

                            <Divider></Divider>
                        </div>

                        <div>
                            <div
                                style={{
                                    borderLeft: "14px solid  #2E8B9A",
                                    height: 30,
                                }}
                            >
                                <h1 style={{ fontWeight: "bold" }}>D</h1>
                            </div>
                            <br />
                            <strong style={{ padding: "20px" }}>
                                DEADLINE
                            </strong>

                            <p style={{ textAlign: "justify" }}>
                                Prazo que o repórter tem para concluir uma
                                matéria.
                            </p>

                            <Divider></Divider>
                        </div>

                        <div>
                            <div
                                style={{
                                    borderLeft: "14px solid  #2E8B9A",
                                    height: 30,
                                }}
                            >
                                <h1 style={{ fontWeight: "bold" }}>E</h1>
                            </div>
                            <br />
                            <strong style={{ padding: "20px" }}>
                                EDITORIA
                            </strong>

                            <p style={{ textAlign: "justify" }}>
                                Categorias temáticas dos jornais. Pode ser de
                                Cidades, Segurança, Economia, Política e
                                Cultura, por exemplo.
                            </p>

                            <Divider></Divider>
                        </div>

                        <div>
                            <div
                                style={{
                                    borderLeft: "14px solid  #2E8B9A",
                                    height: 30,
                                }}
                            >
                                <h1 style={{ fontWeight: "bold" }}>F</h1>
                            </div>
                            <br />
                            <strong style={{ padding: "20px" }}>FONTES</strong>

                            <p style={{ textAlign: "justify" }}>
                                Provedores de informações no jornalismo. Podem
                                ser pessoas, documentos, órgãos públicos, entre
                                outros.
                            </p>

                            <br />
                            <strong style={{ padding: "20px" }}>
                                FREELANCER
                            </strong>

                            <p style={{ textAlign: "justify" }}>
                                Profissional independente, sem vínculos formais
                                com empresas.
                            </p>

                            <Divider></Divider>
                        </div>

                        <div>
                            <div
                                style={{
                                    borderLeft: "14px solid  #2E8B9A",
                                    height: 30,
                                }}
                            >
                                <h1 style={{ fontWeight: "bold" }}>P</h1>
                            </div>
                            <br />
                            <strong style={{ padding: "20px" }}>PAUTA</strong>

                            <p style={{ textAlign: "justify" }}>
                                Recorte de um assunto com potencial de virar
                                notícia. Diferente de tema, que é mais amplo, a
                                pauta tem um direcionamento.
                            </p>
                            <Divider></Divider>
                        </div>

                        <div>
                            <div
                                style={{
                                    borderLeft: "14px solid  #2E8B9A",
                                    height: 30,
                                }}
                            >
                                <h1 style={{ fontWeight: "bold" }}>L</h1>
                            </div>
                            <br />
                            <strong style={{ padding: "20px" }}>
                                LINHA EDITORIAL
                            </strong>

                            <p style={{ textAlign: "justify" }}>
                                Orientação ideológica, visão de mundo e valores
                                de um veículo jornalístico.
                            </p>
                            <Divider></Divider>
                        </div>

                        <div>
                            <div
                                style={{
                                    borderLeft: "14px solid  #2E8B9A",
                                    height: 30,
                                }}
                            >
                                <h1 style={{ fontWeight: "bold" }}>M</h1>
                            </div>
                            <br />
                            <strong style={{ padding: "20px" }}>
                                MÉTRICAS
                            </strong>

                            <p style={{ textAlign: "justify" }}>
                                Números de audiência em um veículo jornalístico.
                            </p>
                            <Divider></Divider>
                        </div>

                        <div>
                            <div
                                style={{
                                    borderLeft: "14px solid  #2E8B9A",
                                    height: 30,
                                }}
                            >
                                <h1 style={{ fontWeight: "bold" }}>N</h1>
                            </div>
                            <br />
                            <strong style={{ padding: "20px" }}>NOTÍCIA</strong>

                            <p style={{ textAlign: "justify" }}>
                                Informação que reúne valores-notícia, como
                                impacto em grande número de pessoas,
                                controvérsia ou ineditismo.
                            </p>
                            <Divider></Divider>
                        </div>

                        <div>
                            <div
                                style={{
                                    borderLeft: "14px solid  #2E8B9A",
                                    height: 30,
                                }}
                            >
                                <h1 style={{ fontWeight: "bold" }}>R</h1>
                            </div>
                            <br />
                            <strong style={{ padding: "20px" }}>REDAÇÃO</strong>

                            <p style={{ textAlign: "justify" }}>
                                Numa empresa jornalística, é o local onde se
                                reúnem repórteres, editores, fotógrafos e demais
                                profissionais que trabalham com informação
                                jornalística.
                            </p>

                            <br />
                            <strong style={{ padding: "20px" }}>RELEASE</strong>

                            <p style={{ textAlign: "justify" }}>
                                É o anúncio de uma pauta, um texto institucional
                                produzido por assessorias de comunicação. A
                                imprensa recebe vários releases diariamente e
                                muitos podem se tornar notícia, mas geralmente
                                com outros enfoques.
                            </p>

                            <strong style={{ padding: "20px" }}>
                                REPORTAGEM
                            </strong>

                            <p style={{ textAlign: "justify" }}>
                                É a notícia ampliada. Traz várias perspectivas
                                sobre a mesma pauta.
                            </p>
                            <Divider></Divider>
                        </div>
                    </Card>
                </Content>
            </Layout>
        </>
    );
}

export default Glossario;
