import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

// const {
//     REACT_APP_API_KEY,
//     REACT_APP_AUTH_DOMAIN,
//     REACT_APP_DATABASE_URL,
//     REACT_APP_PROJECT_ID,
//     REACT_APP_STORAGE_BUCKET,
//     REACT_APP_MESSAGING_SENDER_ID,
//     REACT_APP_APP_ID,
//     REACT_APP_MEASUREMENT_ID,
// } = process.env;

const firebaseConfig = {
  apiKey: "AIzaSyBsK9ZzHes_tq8xq6V3D4CCKe9QE3sBiNs",
  authDomain: "janela-app-386a2.firebaseapp.com",
  databaseURL: "https://janela-app-386a2-default-rtdb.firebaseio.com",
  projectId: "janela-app-386a2",
  storageBucket: "janela-app-386a2.appspot.com",
  messagingSenderId: "27147280860",
  appId: "1:27147280860:web:d4ebfbe55c1f332218ef19",
  measurementId: "G-L5R7B0LVYR"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const database = firebase.database();