import { Layout, Menu, Button, Popover, Avatar, Popconfirm } from "antd";

import Logo from "../../assets/imgs/logoPNG.png";
import { IoLogoInstagram, IoMdMail } from "react-icons/io";
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/auth";
const { Header } = Layout;

const contentRecursos = (
    <div
        style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }}
    >
        <p style={{ paddingRight: "10px" }}>
            {" "}
            <a href="/glossario">GLOSSÁRIO </a>|
        </p>
        <p style={{ paddingRight: "10px" }}>
            {" "}
            <a href="/video">VIDEOAULAS </a>|
        </p>
        <p style={{ paddingRight: "10px" }}>
            {" "}
            <a href="/mapas">MAPAS MENTAIS</a>{" "}
        </p>
    </div>
);

function LayoutContent() {
    const [user, setUser] = useState({} as any);
    const { signOut } = useAuth();

    useEffect(() => {
        const currentUser = JSON.parse(
            localStorage.getItem("@janela:user") as string
        );

        setUser(currentUser);
    }, []);
    return (
        <Layout style={{ paddingBottom: "80px" }}>
            <Header
                style={{
                    backgroundColor: "#FFFFFF",
                    position: "fixed",
                    zIndex: 1,
                    width: "100%",
                    height: "95px",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <div style={{}}>
                        <img
                            src={Logo}
                            alt="logo-janela"
                            style={{ height: "80px", paddingTop: "10px" }}
                        />
                    </div>
                    <div style={{ width: "40%" }}>
                        <Menu
                            style={{
                                backgroundColor: "#FFFFFF",
                                color: "#535F76",
                                fontWeight: "bolder",
                            }}
                            mode="horizontal"
                        >
                            <Menu.Item key="1">
                                <a href="/">INÍCIO</a>
                            </Menu.Item>
                            <Menu.Item key="2">
                                <a href="/licao/1">MÓDULOS</a>
                            </Menu.Item>
                            <Menu.Item key="3">
                                <a href="/blog">BLOG</a>
                            </Menu.Item>
                            <Menu.Item key="4">
                                <Popover
                                    placement="bottom"
                                    title="Ir para.."
                                    content={contentRecursos}
                                >
                                    RECURSOS
                                </Popover>
                            </Menu.Item>

                            <Menu.Item key="5">
                                <a href="/editorial">SOBRE</a>
                            </Menu.Item>
                        </Menu>
                    </div>
                    <div>
                        <Button
                            icon={<IoLogoInstagram style={{ color: "#fff" }} />}
                            shape="circle"
                            href="https://www.instagram.com/projetojanela_/"
                            style={{
                                backgroundColor: "#3E98A4",
                                color: "#FFFFFF",
                                borderColor: "#3E98A4",
                                marginRight: 10,
                            }}
                            color="#52A5B0"
                        />
                        <Button
                            icon={<IoMdMail style={{ color: "#fff" }} />}
                            shape="circle"
                            href="/editorial"
                            style={{
                                backgroundColor: "#3E98A4",
                                color: "#FFFFFF",
                                borderColor: "#3E98A4",
                                marginRight: 80,
                            }}
                        />

                        {user && (
                            <div
                                style={{
                                    display: "inline",
                                    alignItems: "center",
                                }}
                            >
                                <span style={{ marginRight: "20px" }}>
                                    <strong>
                                        Olá, <a href="/perfil">{user.name}</a>
                                    </strong>
                                </span>
                                <Popconfirm
                                    title="Deseja encerrar a sessão?"
                                    onConfirm={signOut}
                                >
                                    <Avatar src={user.profileImagem} />
                                </Popconfirm>
                            </div>
                        )}
                        {!user && (
                            <Button
                                style={{
                                    backgroundColor: "#3E98A4",
                                    color: "#FFFFFF",
                                    borderColor: "#3E98A4",
                                    marginRight: 10,
                                }}
                                href="/login"
                                size="large"
                                type="primary"
                            >
                                Entrar
                            </Button>
                        )}
                    </div>
                </div>
            </Header>
        </Layout>
    );
}

export default LayoutContent;
