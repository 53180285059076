import React from "react";
import { ConfigProvider } from "antd";
import { BrowserRouter, Router } from "react-router-dom";

import ptBR from "antd/lib/locale/pt_BR";

import history from "./services/history";
import Routes from "./pages/routes/routes";
import "./assets/styles/global.css";
import "antd/dist/antd.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "draft-js/dist/Draft.css";

import AppProvider from "./hooks";

import Header from "../src/components/header";
import Footer from "../src/components/footer";

const App: React.FC = () => {
    return (
        <Router history={history}>
            <BrowserRouter>
                <AppProvider>
                    <ConfigProvider locale={ptBR}>
                        <div
                            style={{
                                backgroundColor: "#F0F2F5",
                                width: "100vw",
                                height: "100vh"
                            }}
                        >
                            <Header />
                                <Routes />
                            <Footer />
                        </div>
                    </ConfigProvider>
                </AppProvider>
            </BrowserRouter>
        </Router>
    );
};

export default App;
