import { Button, Layout, Result, Row } from "antd";

const { Content } = Layout;
function notFound() {
    return (
        <Layout>
            <Content>
                <Row
                    justify="space-around"
                    align="middle"
                    style={{
                        padding: 45,
                    }}
                >
                    <Row
                        justify="space-around"
                        align="middle"
                        style={{ padding: 20 }}
                    >
                        <Result
                            status="404"
                            title="Página não encontrada"
                            subTitle="A página que você está tentando acessar, não existe"
                            extra={
                                <Button
                                    type="primary"
                                    size="large"
                                    style={{
                                        backgroundColor: "#3E98A4",
                                        color: "#FFFFFF",
                                        borderColor: "#3E98A4",
                                    }}
                                    href="/"
                                >
                                    Retornar à página anterior
                                </Button>
                            }
                        />
                        ,
                    </Row>
                </Row>
            </Content>
        </Layout>
    );
}

export default notFound;
