/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Layout, Card, Tabs} from "antd";

const { Content } = Layout;
const { TabPane } = Tabs;

function callback(key: any) {
    console.log(key);
}
function Mapas() {
    return (
        <>
            <Layout style={{ backgroundColor: "#F0F2F5" }}>
                <Content
                    className="layout"
                    style={{
                        padding: 24,

                        margin: 0,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Card style={{ maxWidth: "70%" }}>
                        <div
                            style={{
                                borderLeft: "14px solid  #2E8B9A",
                                height: 30,
                            }}
                        >
                            <h1
                                style={{
                                    paddingLeft: "10px",
                                    fontWeight: "bold",
                                    fontFamily: "Ebrima",
                                }}
                            >
                                Vídeoaulas
                            </h1>
                        </div>
                        <br />
                       
                       
                        <div>
                            <p style={{ textAlign: "justify" }}>
                                Confira as videoaulas que fazem parte dos nossos
                                Módulos. <br />
                                Achamos importante reunir aqui para que você
                                possa compartilhar mais facilmente com quem
                                quiser.
                            </p>
                           
                            <Tabs defaultActiveKey="1" onChange={callback}>
                                <TabPane tab="" key="1">
                                    <Card
                                        style={{ backgroundColor: "#EDEDED" }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <iframe
                                                width="660"
                                                height="315"
                                                src="https://www.youtube.com/embed/6m7ipe6QIrg"
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                    </Card>
                                    <br />
                                    <br />
                                    <Card
                                        style={{ backgroundColor: "#EDEDED" }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <iframe
                                                width="660"
                                                height="315"
                                                src="https://www.youtube.com/embed/60G7HE_cekw"
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                    </Card>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Card>
                </Content>
            </Layout>
        </>
    );
}

export default Mapas;
