/* eslint-disable react/prop-types */
import React from "react";
import { AuthProvider } from "./auth";
import { GlossarioProviders } from "./glossario";
import { PostProviders } from "./post";
import { ProfilesProvider } from "./profile";

const AppProvider: React.FC = ({ children }) => (
    <GlossarioProviders>
        <PostProviders>
            <ProfilesProvider>
                <AuthProvider>{children}</AuthProvider>
            </ProfilesProvider>
        </PostProviders>
    </GlossarioProviders>
);

export default AppProvider;
