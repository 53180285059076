import React, { createContext, useCallback, useState, useContext } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { auth, database } from "../config/firebase";

interface SignInCredentials {
  email: string;
  password: string;
}

interface SignUpProps {
  name: string;
  email: string;
  password: string;
  age: number;
  state: string;
  city: string;
  other?: string;
  profile: string;
  profileImage: string;
}

export interface UserProps {
  uid: string;
  name: string;
  email: string;
}

interface AuthContextData {
  user: UserProps;
  loadingAuth: boolean;
  signIn(credentialsSignIn: SignInCredentials): Promise<void>;
  signUp(user: SignUpProps): Promise<void>;
  signOut(): void;
  forgotPassword(email: string): Promise<void>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }: any) => {
  const { push } = useHistory();
  const [currentUser, setCurrentUser] = useState<UserProps>({} as UserProps);
  const [loadingAuth, setLoadingAuth] = useState(false);

  const signIn = useCallback(
    async ({ email, password }) => {
      setLoadingAuth(true);

      Swal.fire({
        title: "Autenticando...",
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: async () => {
          Swal.showLoading();
          await auth
            .signInWithEmailAndPassword(email, password)
            .then(async (res) => {
              const uid = res.user?.uid as string;
              database
                .ref()
                .child(`users/${uid}`)
                .once("value")
                .then(async (snapshot) => {
                  const user = snapshot.val();
                  localStorage.setItem("@janela:user", JSON.stringify(user));
                  setCurrentUser(user as UserProps);
                  Swal.hideLoading();
                  Swal.close();
                  push("/");
                });
            })
            .catch((err) => {
              Swal.hideLoading();
              switch (err.code) {
                case "auth/user-not-found": {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `Email ou senha incorreta`,
                  });
                  break;
                }

                case "auth/user-disabled": {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `Email ou senha incorreta`,
                  });
                  break;
                }

                case "auth/wrong-password": {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `Email ou senha incorreta.`,
                  });
                  break;
                }

                case "auth/invalid-email": {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `Email ou senha incorreta`,
                  });

                  break;
                }

                case "operation-not-allowed": {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `Esse tipo de operação não é permitida.`,
                  });

                  break;
                }

                case "auth/weak-password": {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `A senha que você informou é muito fraca. \nTente utilizar uma senha com pelo menos 6 digitos.`,
                  });
                  break;
                }

                default: {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `Aconteceu algum erro inesperado.\nEntre em contato com o suporte técnico.`,
                  });
                  break;
                }
              }
            })
            .finally(() => {
              setLoadingAuth(false);
            });
        },
      });
    },
    [push]
  );

  const signUp = useCallback(
    async ({
      name,
      email,
      password,
      age,
      state,
      city,
      other,
      profile,
    }) => {
      setLoadingAuth(true);

      Swal.fire({
        title: "Cadastrando...",
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: async () => {
          Swal.showLoading();

          await auth
            .createUserWithEmailAndPassword(email, password)
            .then(async (res) => {
              const uid = res.user?.uid;
              await res.user?.updateProfile({
                displayName: name,
              });

              await database.ref(`jornalists/${uid}`).set({
                uid,
                name,
                email,
                age,
                state,
                city,
                other,
                profile,
              });
            })
            .then(() => {
              Swal.close();
              Swal.fire({
                icon: "success",
                title: "Cadastro de usuário",
                text: `O Seu cadastro foi realizado com sucesso!`,
                showConfirmButton: false,
                timer: 1800,
              }).then(() => push("/"));
            })
            .catch((err) => {
              switch (err.code) {
                case "auth/email-already-in-use": {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `Parece que o email informado já está em uso. \n\nEmail: ${email}`,
                  });
                  break;
                }

                case "auth/invalid-email": {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `Parece que o email é inválido. \n\nEmail: ${email}`,
                  });

                  break;
                }

                case "auth/weak-password": {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `A senha que você informou é muito fraca. \nTente utilizar uma senha com pelo menos 6 digitos.`,
                  });
                  break;
                }

                default: {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Aconteceu algum erro inesperado.\nEntre em contato com o suporte técnico.",
                  });
                  break;
                }
              }
            })
            .finally(() => setLoadingAuth(false));
        },
      });
    },
    [push]
  );

  const signOut = useCallback(async () => {
    await auth.signOut().then(async () => {
      localStorage.removeItem("@janela:user");
      setCurrentUser({} as UserProps);
      push("/");
    });
  }, [push]);

  const forgotPassword = useCallback(async (email) => {
    await auth
      .sendPasswordResetEmail(email)
      .then(() =>
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `O link de recueração de senha foi enviado para o email: ${email} \n\nObs: Não deixe de verificar no spam.`,
        })
      )
      .catch(() =>
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Parece que o email informado não é válido. \n\nEmail: ${email}`,
        })
      );
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signUp,
        signOut,
        forgotPassword,
        user: currentUser,
        loadingAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
