import {
    Button,
    Card,
    Divider,
    Form,
    Input,
    Layout,
    Row,
    Select,
    Tag,
    Upload,
} from "antd";
import firebase from "firebase";
import { useCallback, useState } from "react";

import { Editor, EditorState } from "draft-js";


import Swal from "sweetalert2";
import { usePost } from "../../../hooks/post";

const { Option } = Select;
const { Content } = Layout;
const { TextArea } = Input;
var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
var yyyy = today.getFullYear();

const date = mm + "/" + dd + "/" + yyyy;


function MyEditor() {
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    return <Editor editorState={editorState} onChange={setEditorState} />;
}

function handlePost() {
    Swal.fire({
        icon: "success",
        title: "Matéria Publicada",
        text: "Consulte a sessão de matérias para identificação",
    });
}

function NewPost() {
    const { createPost } = usePost();
    const [categoria, setCategoria] = useState("Pauta");
    const [cartolas, setCartolas] = useState("");
    const [imagem, setImagem] = useState("");
    const [autorImagem, setAutorImagem] = useState("");

    function geraStringAleatoria(tamanho: number) {
        let stringAleatoria = "";
        const caracteres =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < tamanho; i += 1) {
            stringAleatoria += caracteres.charAt(
                Math.floor(Math.random() * caracteres.length)
            );
        }
        return stringAleatoria;
    }

    const customUpload = async ({ file }: any) => {
        const storage = firebase.storage();
        const metadata = {
            contentType: "image/jpeg",
        };

        const storageRef = storage.ref();
        const nick = geraStringAleatoria(16);

        const imgFile = storageRef.child(`imagens/${nick}.png`);
        Swal.fire({
            title: "Upando...",
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();
                try {
                    await imgFile.put(file, metadata);
                    console.log("enviou");
                } catch (e) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Aconteceu algum erro inesperado.\nTente novamente.",
                    });
                    console.log(e);
                }
                try {
                    await storageRef
                        .child(`imagens/${nick}.png`)
                        .getDownloadURL()
                        .then((url) => {
                            const tumblrUrl = url;
                            setImagem(tumblrUrl);
                            Swal.close();
                        });
                    Swal.fire({
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1000,
                    });
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Aconteceu algum erro inesperado.\nTente novamente.",
                    });
                    console.log(error);
                }
            },
        });
    };

    const customUploadImage = async ({ file }: any) => {
        const storage = firebase.storage();
        const metadata = {
            contentType: "image/jpeg",
        };

        const storageRef = storage.ref();
        const nick = geraStringAleatoria(16);

        const imgFile = storageRef.child(`imagens/${nick}.png`);

        Swal.fire({
            title: "Upando...",
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();
                try {
                    await imgFile.put(file, metadata);
                    console.log("enviou");
                } catch (e) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Aconteceu algum erro inesperado.\nTente novamente.",
                    });
                    console.log(e);
                }
                try {
                    await storageRef
                        .child(`imagens/${nick}.png`)
                        .getDownloadURL()
                        .then((url) => {
                            const tumblrUrl = url;
                            setAutorImagem(tumblrUrl);
                            Swal.close();
                        });
                    Swal.fire({
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1000,
                    });
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Aconteceu algum erro inesperado.\nTente novamente.",
                    });
                    console.log(error);
                }
            },
        });
    };

    interface FormProps {
        autor: string;
        titulo: string;
        subtitulo: string;
        materia: string;
        bio: string;
        photoCredit: string;
        tempoLeitura: string;
        favcolor: string;
    }

    const onFinish = useCallback(
        async ({
            autor,
            titulo,
            subtitulo,
            materia,
            bio,
            photoCredit,
            tempoLeitura,
            favcolor,
        }: FormProps) => {
            await createPost({
                autor,
                titulo,
                subtitulo,
                cartolas,
                materia,
                bio,
                categoria,
                imagem,
                date,
                photoCredit,
                autorImagem,
                tempoLeitura,
                favcolor,
            });
            handlePost();
        },
        [createPost, cartolas, categoria, imagem, autorImagem]
    );

    return (
        <Layout>
            <Content>
                <Row
                    justify="space-around"
                    align="middle"
                    style={{
                        padding: 45,
                    }}
                >
                    <Row
                        justify="space-around"
                        align="middle"
                        style={{ padding: 20 }}
                    >
                        <Card style={{ width: "60vw", padding: 30 }}>
                            <div className="sign-in-title">
                                <h2
                                    style={{
                                        color: "#52a5b0",
                                        fontWeight: 600,
                                        paddingTop: 20,
                                        alignSelf: "start",
                                    }}
                                >
                                    Adicionar Nova Publicação
                                </h2>
                                <p
                                    style={{
                                        alignSelf: "start",
                                        fontWeight: 700,
                                    }}
                                >
                                    Preencha as informações abaixo para
                                    continuar:
                                </p>
                            </div>
                            <Divider />

                            <Form
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                                // onFinishFailed={onFinishFailed}
                            >
                                <strong>
                                    <span
                                        style={{
                                            color: "#F05152",
                                            marginRight: 4,
                                        }}
                                    >
                                        *
                                    </span>
                                    Título da Publicação
                                </strong>
                                <Form.Item
                                    name="titulo"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Por favor, insira o título da sua matéria!",
                                        },
                                    ]}
                                >
                                    <Input
                                        name="titulo"
                                        allowClear
                                        size="large"
                                        placeholder="Insira o título da matéria"
                                    />
                                </Form.Item>
                                <strong>
                                    <span
                                        style={{
                                            color: "#F05152",
                                            marginRight: 4,
                                        }}
                                    >
                                        *
                                    </span>
                                    Subtítulo da Publicação
                                </strong>

                                <Form.Item
                                    name="subtitulo"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Por favor, insira o subtítulo da matéria!",
                                        },
                                    ]}
                                >
                                    <Input
                                        name="subtitulo"
                                        allowClear
                                        size="large"
                                        placeholder="Insira o subtítulo da matéria"
                                    />
                                </Form.Item>

                                <Form.Item name="cartolas">
                                    <strong>
                                        <span
                                            style={{
                                                color: "#F05152",
                                                marginRight: 4,
                                            }}
                                        >
                                            *
                                        </span>
                                        Cartola
                                    </strong>
                                    <Input
                                        name="cartolas"
                                        placeholder="Insira uma cartola..."
                                        size="large"
                                        onChange={(e) => {
                                            setCartolas(e.target.value);
                                        }}
                                    />
                                </Form.Item>

                                <Divider orientation="left">
                                    Informações sobre a matéria
                                </Divider>
                                <strong>
                                    <span
                                        style={{
                                            color: "#F05152",
                                            marginRight: 4,
                                        }}
                                    >
                                        *
                                    </span>
                                    Cor da tag
                                </strong>
                                <Form.Item
                                    name="favcolor"
                                    style={{ marginTop: "10px" }}
                                >
                                    <Input
                                        type="color"
                                        id="favcolor"
                                        name="favcolor"
                                        value="#ff0000"
                                    />
                                </Form.Item>

                                <strong>
                                    <span
                                        style={{
                                            color: "#F05152",
                                            marginRight: 4,
                                        }}
                                    >
                                        *
                                    </span>
                                    Escreva sua Matéria
                                </strong>
                                <MyEditor />
                                <Form.Item
                                    name="materia"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Por favor, escreva uma texto para a matéria.",
                                        },
                                    ]}
                                >
                                    <TextArea
                                        rows={4}
                                        name="materia"
                                        allowClear
                                        placeholder="Escreva a publicação aqui..."
                                    />
                                </Form.Item>
                                <strong>Upload de Imagem da publicação</strong>
                                <br />

                                <Upload
                                    accept=".png, .jpg, .jpeg"
                                    maxCount={1}
                                    name="imagens"
                                    customRequest={customUpload}
                                    style={{ width: "100%" }}
                                    listType="picture"
                                >
                                    <Button>Selecione...</Button>
                                </Upload>
                                <br />
                                <strong>
                                    <span
                                        style={{
                                            color: "#F05152",
                                            marginRight: 4,
                                        }}
                                    >
                                        *
                                    </span>
                                    Tempo de Leitura da Matéria
                                </strong>

                                <Form.Item
                                    name="tempoLeitura"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Por favor, digite um tempo de leitura",
                                        },
                                    ]}
                                >
                                    <Input
                                        name="tempoLeitura"
                                        placeholder="Insira o tempo de leitura..."
                                        size="large"
                                    />
                                </Form.Item>
                                <strong>
                                    <span
                                        style={{
                                            color: "#F05152",
                                            marginRight: 4,
                                        }}
                                    >
                                        *
                                    </span>
                                    Créditos da imagem
                                </strong>
                                <Form.Item
                                    name="photoCredit"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Por favor, insira o crédito da imagem!",
                                        },
                                    ]}
                                >
                                    <Input
                                        name="photoCredit"
                                        placeholder="Insira os créditos da imagem"
                                        size="large"
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <strong>
                                        <span
                                            style={{
                                                color: "#F05152",
                                                marginRight: 4,
                                            }}
                                        >
                                            *
                                        </span>
                                        Categorias
                                    </strong>
                                    <Select
                                        size="large"
                                        defaultValue="pauta"
                                        onChange={(value) => {
                                            setCategoria(value);
                                        }}
                                    >
                                        <Option value="pauta">Pauta</Option>
                                        <Option value="apuracao">
                                            Apuração
                                        </Option>
                                        <Option value="profissao">
                                            Profissão
                                        </Option>
                                        <Option value="edicao">Edição</Option>
                                        <Option value="opniao">Opinião</Option>
                                    </Select>
                                </Form.Item>
                                <Divider orientation="left">
                                    Informações sobre o(a) autor(a)
                                </Divider>
                                <strong>
                                    <span
                                        style={{
                                            color: "#F05152",
                                            marginRight: 4,
                                        }}
                                    >
                                        *
                                    </span>
                                    Upload de Imagem do Autor
                                </strong>

                                <br />

                                <Upload
                                    accept=".png, .jpg, .jpeg"
                                    maxCount={1}
                                    name="imagens"
                                    customRequest={customUploadImage}
                                    style={{ width: "100%" }}
                                    listType="picture"
                                >
                                    <Button>Selecione...</Button>
                                </Upload>
                                <br />

                                <strong>
                                    <span
                                        style={{
                                            color: "#F05152",
                                            marginRight: 4,
                                        }}
                                    >
                                        *
                                    </span>
                                    Nome do autor
                                </strong>

                                <Form.Item
                                    name="autor"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Por favor, insira seu nome!",
                                        },
                                    ]}
                                >
                                    <Input
                                        name="autor"
                                        allowClear
                                        size="large"
                                        placeholder="Insira o seu nome"
                                    />
                                </Form.Item>

                                <strong>
                                    <span
                                        style={{
                                            color: "#F05152",
                                            marginRight: 4,
                                        }}
                                    >
                                        *
                                    </span>
                                    Mini Bio do Autor
                                </strong>

                                <Form.Item
                                    name="bio"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Por favor, insira a mini bio do autor",
                                        },
                                    ]}
                                >
                                    <TextArea
                                        rows={4}
                                        name="bio"
                                        placeholder="Insira a mini bio do autor"
                                        allowClear
                                        size="large"
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <strong>
                                        <span
                                            style={{
                                                color: "#F05152",
                                                marginRight: 4,
                                            }}
                                        >
                                            *
                                        </span>
                                        Tags
                                    </strong>
                                    <br />
                                    <div>
                                        <Tag color="#7CC0CA">Pauta</Tag>
                                        <Tag color="#E9483E">Apuração</Tag>
                                        <Tag color="#EA6659">Jornalista</Tag>
                                        <Tag color="#F39F59">Edição</Tag>
                                        <Tag color="#008A99">Opnião</Tag>
                                    </div>
                                </Form.Item>

                                <Form.Item style={{ float: "right" }}>
                                    <Button
                                        htmlType="submit"
                                        // loading={loading}
                                        type="primary"
                                        size="large"
                                        // onClick={handlePost}
                                        style={{
                                            backgroundColor: "#3E98A4",
                                            color: "#FFFFFF",
                                            borderColor: "#3E98A4",
                                        }}
                                    >
                                        Criar Matéria
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Row>
                </Row>
            </Content>
        </Layout>
    );
}

export default NewPost;
