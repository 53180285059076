import { Layout, Row, Button, Card, Image } from "antd";
import Meta from "antd/lib/card/Meta";

import Capa1 from "../../assets/imgs/CAPA1.png";

import Capa2 from "../../assets/imgs/Capa2.png";

import Capa3 from "../../assets/imgs/CAPA3.png";

import Capa4 from "../../assets/imgs/Capa4.png";

const { Content } = Layout;

function Licoes() {
    return (
        <Layout>
            <Content>
                <Row
                    justify="space-around"
                    style={{
                        padding: 45,
                    }}
                >
                    <Row
                        justify="space-around"
                        align="middle"
                        style={{ padding: 20 }}
                    >
                        <Card
                            style={{
                                width: "800px",
                                padding: 10,
                                height: "100%",
                            }}
                        >
                            <div
                                style={{
                                    borderLeft: "14px solid  #F4A973",
                                    height: 30,
                                }}
                            >
                                <h1
                                    style={{
                                        paddingLeft: "10px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    MÓDULOS
                                </h1>
                                <p>
                                    <strong>
                                        Selecione a lição que deseja visualizar:
                                    </strong>
                                </p>
                                <br />
                                <br />
                            </div>
                            <br />
                            <br />

                            <Card hoverable>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        maxWidth: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignItems: "center",
                                            display: "flex",
                                            width: "50%",
                                        }}
                                    >
                                        <Meta
                                            avatar={
                                                <Image
                                                    width={200}
                                                    src={Capa1}
                                                />
                                            }
                                        />
                                        <div>
                                            <h1>
                                                <a href="/licao/1">
                                                    PAUTA E NOTICIABILIDADE
                                                </a>
                                            </h1>
                                        </div>
                                    </div>

                                    <br />
                                    <br />
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Button
                                            type="dashed"
                                            href="/licao/1"
                                            style={{
                                                borderColor: "#318E9B",
                                            }}
                                        >
                                            Acessar Módulo
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                            <br />
                            <Card hoverable>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        maxWidth: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignItems: "center",
                                            display: "flex",
                                            width: "50%",
                                        }}
                                    >
                                        <Meta
                                            avatar={
                                                <Image
                                                    width={200}
                                                    src={Capa2}
                                                />
                                            }
                                        />
                                        <div>
                                            <h1>
                                                <a href="/licao/1">APURAÇÃO</a>
                                            </h1>
                                        </div>
                                    </div>

                                    <br />
                                    <br />
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Button
                                            type="dashed"
                                            href="/licao/1"
                                            style={{
                                                borderColor: "#318E9B",
                                            }}
                                        >
                                            Acessar Módulo
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                            <br />
                            <Card hoverable>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        maxWidth: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignItems: "center",
                                            display: "flex",
                                            width: "50%",
                                        }}
                                    >
                                        <Meta
                                            avatar={
                                                <Image
                                                    width={200}
                                                    src={Capa3}
                                                />
                                            }
                                        />
                                        <div>
                                            <h1>
                                                <a href="/licao/1">EDIÇÃO</a>
                                            </h1>
                                        </div>
                                    </div>

                                    <br />
                                    <br />
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Button
                                            type="dashed"
                                            href="/licao/1"
                                            style={{
                                                borderColor: "#318E9B",
                                            }}
                                        >
                                            Acessar Módulo
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                            <br />
                            <Card hoverable>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        maxWidth: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignItems: "center",
                                            display: "flex",
                                            width: "50%",
                                        }}
                                    >
                                        <Meta
                                            avatar={
                                                <Image
                                                    width={200}
                                                    src={Capa4}
                                                />
                                            }
                                        />
                                        <div>
                                            <h1>
                                                <a href="/licao/1">PROFISSÃO</a>
                                            </h1>
                                        </div>
                                    </div>

                                    <br />
                                    <br />
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Button
                                            type="dashed"
                                            href="/licao/1"
                                            style={{
                                                borderColor: "#318E9B",
                                            }}
                                        >
                                            Acessar Módulo
                                        </Button>
                                    </div>
                                </div>
                            </Card>

                            {/* <Card hoverable>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                maxWidth: "100%",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    alignItems: "center",
                                                    display: "flex",
                                                    width: "50%",
                                                }}
                                            >
                                                <Meta
                                                    avatar={
                                                        <Image
                                                            width={200}
                                                            src=""
                                                        />
                                                    }
                                                />
                                                <div>
                                                    <Tag color="blue">
                                                        Mundo
                                                    </Tag>
                                                    <br />
                                                    <br />
                                                    <h1>
                                                        <a href="/licao/1">
                                                            NOTICIABILIDADE
                                                        </a>
                                                    </h1>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Button
                                                    icon={<HolderOutlined />}
                                                    type="primary"
                                                    shape="circle"
                                                    style={{
                                                        backgroundColor:
                                                            "#318E9B",
                                                        borderColor: "#318E9B",
                                                    }}
                                                />
                                                <br />
                                                <br />
                                                <br />
                                                <Button
                                                    icon={<ArrowDownOutlined />}
                                                    type="dashed"
                                                    shape="circle"
                                                    style={{
                                                        borderColor: "#318E9B",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Card> */}
                        </Card>
                    </Row>
                </Row>
            </Content>
        </Layout>
    );
}

export default Licoes;
