import { Layout} from "antd";

import Logo from "../../assets/imgs/logoPNG.png";

function Footer() {
    return (
        <Layout>
            <div
                style={{
                    backgroundColor: "#FFFFFF",
                    width: "100%",
                    height: "100%",
                    bottom: 0,

                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        margin: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <div></div>

                    <div style={{ textAlign: "center", paddingRight: "120px" }}>
                        <img
                            src={Logo}
                            alt="logo-janela"
                            style={{ height: "60px" }}
                        />
                        <div style={{ textAlign: "left" }}>
                            <span>Copyright © 2021 | Janela.</span>
                            <br />
                            <span> Todos os direitos reservados.</span>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Footer;
