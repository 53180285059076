import { useCallback, useEffect, useState } from "react";
import { useProfiles } from "../../hooks/profile";
import {
    Button,
    Card,
    Divider,
    Form,
    Input,
    InputNumber,
    Layout,
    Row,
    Select,
    Col,
    Upload,
} from "antd";

import Swal from "sweetalert2";
import firebase from "firebase";
// import { useAuth } from "../../hooks/auth";

import { UploadOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Content } = Layout;

function Signup() {
    const { createProfiles } = useProfiles();
    const [profile, setProfile] = useState("Estudante");
    const [state, setState] = useState("CE");
    console.log(setState);
    const [city, setCity] = useState("");
    const [other, setOther] = useState("default");

    const [uf, setUf] = useState("AC");
    const [listUf, setListUf] = useState([]);
    const [listCity, setListCity] = useState([]);
    const [profileImagem, setProfileImagem] = useState("");

    console.log("Dados", profile, state, city, other, listUf, listCity);
    console.log(setProfile, setUf);

    function loadUf() {
        let url = "https://servicodados.ibge.gov.br/api/v1/localidades/estados";
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                data.sort((a: { nome: string }, b: { nome: any }) =>
                    a.nome.localeCompare(b.nome)
                );
                console.log(data);
                setListUf(data);
            });
    }
    // console.log(setCity, setOther, setState, setProfile);

    function loadCity(id: any) {
        let url = "https://servicodados.ibge.gov.br/api/v1/";
        url = url + `localidades/estados/${id}/municipios`;
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                let dataa = data.sort((a: any, b: any) =>
                    a.nome.localeCompare(b.nome)
                );
                setListCity(dataa);
            });
    }

    function geraStringAleatoria(tamanho: number) {
        let stringAleatoria = "";
        const caracteres =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < tamanho; i += 1) {
            stringAleatoria += caracteres.charAt(
                Math.floor(Math.random() * caracteres.length)
            );
        }
        return stringAleatoria;
    }

    const customUploadProfile = async ({ file }: any) => {
        const storage = firebase.storage();
        const metadata = {
            contentType: "image/jpeg",
        };

        const storageRef = storage.ref();
        const nick = geraStringAleatoria(16);

        const imgFile = storageRef.child(`imagens/${nick}.png`);
        Swal.fire({
            title: "Upando...",
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();
                try {
                    await imgFile.put(file, metadata);
                    console.log("enviou");
                } catch (e) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Aconteceu algum erro inesperado.\nTente novamente.",
                    });
                    console.log(e);
                }
                try {
                    await storageRef
                        .child(`imagens/${nick}.png`)
                        .getDownloadURL()
                        .then((url) => {
                            const tumblrUrl = url;
                            setProfileImagem(tumblrUrl);
                            Swal.close();
                        });
                    Swal.fire({
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1000,
                    });
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Aconteceu algum erro inesperado.\nTente novamente.",
                    });
                    console.log(error);
                }
            },
        });
    };

    useEffect(() => {
        loadUf();
    }, []);
    useEffect(() => {
        if (uf) {
            loadCity(uf);
        }
    }, [uf]);

    interface FormProps {
        uid: string;
        name: string;
        email: string;
        password: string;
        age: number;
        city: string;
        state: string;
        other?: string;
        profile: string;
        profileImagem: string;
    }

    const handleAddProfile = useCallback(
        async ({ name, email, password, age, city, profile }: FormProps) => {
            await createProfiles({
                name,
                email,
                password,
                age,
                state,
                city,
                other,
                profile,
                profileImagem,
            });
        },
        [createProfiles, state, other, profileImagem]
    );

    const onFinishFailed = () => {
        Swal.fire({
            title: "É obrigatório preencher todos os campos",
            icon: "error",
            allowEscapeKey: false,
        });
    };

    return (
        <Layout>
            <Content>
                <Row
                    justify="space-around"
                    align="middle"
                    style={{
                        padding: 45,
                    }}
                >
                    <Row
                        justify="space-around"
                        align="middle"
                        style={{ padding: 20 }}
                    >
                        <Card
                            className="viewRegistration"
                            style={{ width: "60vw", padding: 30 }}
                        >
                            <div className="sign-in-title">
                                <h2
                                    style={{
                                        color: "#52a5b0",
                                        fontWeight: 600,
                                        paddingTop: 20,
                                        alignSelf: "start",
                                    }}
                                >
                                    Podemos te conhecer melhor?
                                </h2>
                                <p
                                    style={{
                                        alignSelf: "start",
                                        fontWeight: 700,
                                    }}
                                >
                                    Preencha as informações abaixo para
                                    continuar" por "Com o cadastro, você pode
                                    interagir com o Janela comentando posts do
                                    Blog e enviando sugestões para o Glossário
                                </p>
                            </div>
                            <Divider />
                            <Form
                                onFinish={handleAddProfile}
                                onFinishFailed={onFinishFailed}
                            >
                                <strong>
                                    <span
                                        style={{
                                            color: "#F05152",
                                            marginRight: 4,
                                        }}
                                    >
                                        *
                                    </span>
                                    Nome Completo
                                </strong>
                                <Form.Item
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Por favor, insira seu nome!",
                                        },
                                        {
                                            type: "string",
                                            message:
                                                "Por favor, insira um nome válido",
                                        },
                                    ]}
                                >
                                    <Input
                                        value="name"
                                        allowClear
                                        size="large"
                                        placeholder="Insira seu nome"
                                    />
                                </Form.Item>
                                <strong>
                                    <span
                                        style={{
                                            color: "#F05152",
                                            marginRight: 4,
                                        }}
                                    >
                                        *
                                    </span>
                                    Email
                                </strong>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Por favor, insira seu email!",
                                        },
                                        {
                                            type: "string",
                                            message:
                                                "Por favor, insira um email válido",
                                        },
                                    ]}
                                >
                                    <Input
                                        name="email"
                                        allowClear
                                        size="large"
                                        placeholder="Insira seu email"
                                    />
                                </Form.Item>
                                <strong>
                                    <span
                                        style={{
                                            color: "#F05152",
                                            marginRight: 4,
                                        }}
                                    >
                                        *
                                    </span>
                                    Senha
                                </strong>

                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Por favor, insira uma senha!",
                                        },
                                        {
                                            type: "string",
                                            max: 16,
                                            message:
                                                "Por favor, insira um uma senha válida",
                                        },
                                        {
                                            message:
                                                "Sua senha precisa ter no mínimo 06 caracteres",
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        name="password"
                                        allowClear
                                        type="password"
                                        size="large"
                                        placeholder="Insira sua senha"
                                    />
                                </Form.Item>
                                <strong>
                                    <span
                                        style={{
                                            color: "#F05152",
                                            marginRight: 4,
                                        }}
                                    >
                                        *
                                    </span>
                                    Foto para perfil
                                </strong>
                                        <br/>
                                <Upload
                                     accept=".png, .jpg, .jpeg"
                                     maxCount={1}
                                     name="imagens"
                                     customRequest={customUploadProfile}
                                     style={{ width: "100%" }}
                                     listType="picture"
                                >
                                    <Button
                                        icon={<UploadOutlined />}
                                        type="primary"
                                        style={{
                                            backgroundColor: "  #3E98A4",
                                            color: "#FFFFFF",
                                            borderColor: "#3E98A4",
                                        }}
                                    >
                                        Enviar foto
                                    </Button>
                                </Upload>
                                        <br/>
                                <Row gutter={21}>
                                    <Col span={5}>
                                        <strong>Você é:</strong>
                                        <Form.Item name="profile">
                                            <Select
                                                size="large"
                                                placeholder="Selecione uma opção..."
                                                // onChange={(value) => {
                                                //     setProfile(value);
                                                // }}
                                                style={{ width: "100%" }}
                                            >
                                                <Option value="estudante">
                                                    Estudante
                                                </Option>
                                                <Option value="professor">
                                                    Professor
                                                </Option>
                                                <Option value="jornalista">
                                                    Jornalista
                                                </Option>
                                                <Option value="outro">
                                                    Outro
                                                </Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <strong>
                                            <span
                                                style={{
                                                    color: "#F05152",
                                                    marginRight: 4,
                                                }}
                                            >
                                                *
                                            </span>
                                            Idade:
                                        </strong>
                                        <Form.Item
                                            name="age"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Por favor, insira sua idade!",
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                min={12}
                                                max={120}
                                                style={{ width: "100%" }}
                                                name="age"
                                                size="large"
                                                placeholder="Digite sua idade"
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={5}>
                                        <Form.Item name="state">
                                            <strong>
                                                <span
                                                    style={{
                                                        color: "#F05152",
                                                        marginRight: 4,
                                                    }}
                                                >
                                                    *
                                                </span>
                                                Estado
                                            </strong>

                                            {/* <Select></Select> */}
                                            <Select
                                                size="large"
                                                showSearch
                                                onChange={(value) => {
                                                    // setState(value);
                                                    console.log(value);
                                                }}
                                                style={{ width: "100%" }}
                                                placeholder="Selecione um estado:"
                                            >
                                                {/* <div>
                                                    <select
                                                        value={uf}
                                                        onChange={(e) =>
                                                            setUf(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {listUf.map((a, b) => (
                                                            <Option value={a}>
                                                                Acre
                                                            </Option>
                                                        ))}
                                                    </select>
                                                    <select
                                                        value={city}
                                                        onChange={(e) =>
                                                            setCity(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {listCity.map(
                                                            (a, b) => (
                                                                <Option
                                                                    value={a}
                                                                >
                                                                    Acre
                                                                </Option>
                                                            )
                                                        )}
                                                    </select>
                                                </div> */}

                                                <Option value="Acre">
                                                    Acre
                                                </Option>
                                                <Option value="Alagoas">
                                                    Alagoas
                                                </Option>
                                                <Option value="Amapá">
                                                    Amapá
                                                </Option>
                                                <Option value="Amazonas">
                                                    Amazonas
                                                </Option>
                                                <Option value="Bahia">
                                                    Bahia
                                                </Option>
                                                <Option value="Ceará">
                                                    Ceará
                                                </Option>
                                                <Option value="Distrito Federal">
                                                    Distrito Federal
                                                </Option>
                                                <Option value="Espírito Santo">
                                                    Espírito Santo
                                                </Option>
                                                <Option value="Goiás">
                                                    Goiás
                                                </Option>
                                                <Option value="Maranhão">
                                                    Maranhão
                                                </Option>
                                                <Option value="Mato Grosso">
                                                    Mato Grosso
                                                </Option>
                                                <Option value="Mato Grosso do Sul">
                                                    Mato Grosso do Sul
                                                </Option>
                                                <Option value="Minas Gerais">
                                                    Minas Gerais
                                                </Option>
                                                <Option value="Paraíba">
                                                    Paraíba
                                                </Option>
                                                <Option value="Paraná">
                                                    Minas Gerais
                                                </Option>
                                                <Option value="Pernambuco">
                                                    Pernambuco
                                                </Option>
                                                <Option value="Piauí">
                                                    Piauí
                                                </Option>
                                                <Option value="Rio de Janeiro">
                                                    Rio de Janeiro
                                                </Option>
                                                <Option value="Rio Grande do Norte">
                                                    Rio Grande do Norte
                                                </Option>
                                                <Option value="Rio Grande do Sul">
                                                    Rio Grande do Sul
                                                </Option>
                                                <Option value="Rondônia">
                                                    Rondônia
                                                </Option>
                                                <Option value="Roraima">
                                                    Roraima
                                                </Option>
                                                <Option value="Santa Catarina">
                                                    Santa Catarina
                                                </Option>
                                                <Option value="São Paulo">
                                                    São Paulo
                                                </Option>
                                                <Option value="Sergipe">
                                                    Sergipe
                                                </Option>
                                                <Option value="Tocantins">
                                                    Tocantins
                                                </Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={9}>
                                        <strong>
                                            <span
                                                style={{
                                                    color: "#F05152",
                                                    marginRight: 4,
                                                }}
                                            >
                                                *
                                            </span>
                                            Cidade
                                        </strong>
                                        <Form.Item name="city">
                                            <Input
                                                name="city"
                                                size="large"
                                                placeholder="Digite o nome de sua cidade..."
                                                onChange={(e) => {
                                                    setCity(e.target.value);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <strong>
                                    Se você selecionou "Outro", descreva o seu
                                    perfil:
                                </strong>
                                <Form.Item name="other">
                                    <Input
                                        name="other"
                                        onChange={(e) => {
                                            setOther(e.target.value);
                                        }}
                                        allowClear
                                        size="large"
                                        placeholder="Descreva seu perfil"
                                    />
                                </Form.Item>

                                {/* <Form.Item style={{ float: "right" }}> */}
                                <p
                                    style={{
                                        fontSize: 10,
                                        fontWeight: "bold",
                                    }}
                                >
                                    Por que pedimos e ssas informações?
                                    <br /> Suas informações vão compor seu
                                    próprio cadastro e ficarão armazenadas na
                                    nossa base de dados, que é acessada somente
                                    por nós.
                                </p>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    size="large"
                                    style={{
                                        float: "right",
                                        backgroundColor: "  #3E98A4",
                                        color: "#FFFFFF",
                                        borderColor: "#3E98A4",
                                    }}
                                >
                                    Cadastrar-se
                                </Button>
                                {/* </Form.Item> */}
                            </Form>
                        </Card>
                    </Row>
                </Row>
            </Content>
        </Layout>
    );
}

export default Signup;
